import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import useLocale from '../../../../CustomHooks/useLocale';
import { useStores } from '../../../../root-store-context';
import { FormattedMessage } from 'react-intl';
import Typography from '@mui/material/Typography';

import { NAVIGATION_ROUTES } from '../../../../const';

const ActivateUser = () => {
    // TODO сделать не через компонент, а через запрос в апи и редирект

    const { localeUrlPrefix } = useLocale();
    const { user } = useStores();
    const [urlSearchParams] = useSearchParams();
    const token = urlSearchParams.get('token');
    const navigate = useNavigate();

    useEffect(() => {
        if (user.isLogined) {
            if (user.data?.isActivated) {
                navigate(`${localeUrlPrefix}${NAVIGATION_ROUTES.cabinet}`);
            } else {
                try {
                    if (!token) {
                        throw 'there is not token';
                    }

                    const payload = JSON.parse(atob(token.split('.')[1]));

                    // expiration time check on server side due to client time is not set correctly!!!
                    if (payload.email !== user.data?.email) {
                        throw 'invalid token';
                    }

                    user.activate({ token }).then(() => {
                        if (user.state === 'ok') {
                            navigate(`${localeUrlPrefix}${NAVIGATION_ROUTES.cabinet}`);
                        } else {
                            return (
                                <>
                                    <Typography color="error" variant="h1">
                                        <FormattedMessage
                                            defaultMessage="Произошла ошибка. Попробуйте позже."
                                            id="Register_error"
                                            description="форма регистрации"
                                        />
                                    </Typography>
                                </>
                            );
                        }
                    });
                } catch (e) {
                    // TODO сообщить об ошибке неправильного токена
                    navigate(`${localeUrlPrefix}${NAVIGATION_ROUTES.cabinet}`);
                }
            }
        } else {
            const retpath = `${localeUrlPrefix}${NAVIGATION_ROUTES.user_activate}?token=${token}`;

            navigate(
                `${localeUrlPrefix}${NAVIGATION_ROUTES.login}?retpath=${encodeURIComponent(
                    retpath
                )}`
            );
        }
    }, []);

    return <></>;
};

export default observer(ActivateUser);
