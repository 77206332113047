import { useMediaQuery, useTheme } from '@mui/material';
import { ReactNode } from 'react';
import { Box, CircularProgress } from '@mui/material';

type CircularProgressWrapperProps = {
    isSubmitting: boolean;
    children: ReactNode;
    justify?: 'start' | 'center' | 'end' | 'stretch' | 'initial';
    align?: 'start' | 'center' | 'end' | 'stretch' | 'initial';
    fullWidth?: boolean;
};

function CircularProgressWrapper({
    isSubmitting,
    children,
    justify = 'initial',
    align = 'initial',
    fullWidth
}: CircularProgressWrapperProps) {
    const theme = useTheme();
    const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.values.sm - 1}px)`);
    const width = isMobile ? '100%' : fullWidth ? '100%' : 'initial';
    const justifySelf = isMobile ? 'initial' : justify;
    const alignSelf = isMobile ? 'initial' : align;

    return (
        <Box
            sx={{
                display: 'grid',
                placeItems: 'center',
                justifySelf: justifySelf,
                alignSelf: alignSelf,
                width: isMobile ? '100%' : 'initial'
            }}
        >
            <div
                style={{
                    width: width,
                    gridColumn: '1',
                    gridRow: '1'
                }}
            >
                {children}
            </div>
            {isSubmitting && (
                <CircularProgress
                    size={24}
                    sx={{
                        color: 'primary',
                        zIndex: '1',
                        gridColumn: '1',
                        gridRow: '1'
                    }}
                />
            )}
        </Box>
    );
}

export default CircularProgressWrapper;
