import { FormattedMessage } from 'react-intl';
import Typography from '@mui/material/Typography';
import DownloadIcon from '@mui/icons-material/Download';
import Link from '@mui/material/Link';
import { APP_DOWNLOAD_LINK_MAC, CURRENT_APP_VERSION } from '../../../../const';
import classes from './DownloadOptions.module.css';

function DownloadMac() {
    return (
        <div className={classes['download-options']}>
            <div>
                <dl className={classes['download-options__requirements']}>
                    <div>
                        <dt>
                            <Typography
                                variant="h5"
                                component="p"
                                className={classes['download-options__subtitle']}
                            >
                                <FormattedMessage
                                    defaultMessage="Минимальные"
                                    id="downloadAppRequirementsMin"
                                    description="скачать"
                                />
                            </Typography>
                        </dt>
                        <dd className={classes['download-options__requirement']}>
                            <FormattedMessage
                                defaultMessage="MacOS 12.5 Monterey и выше"
                                id="downloadAppRequirementsMinMac"
                                description="скачать"
                            />
                        </dd>
                        <dd className={classes['download-options__requirement']}>
                            <FormattedMessage
                                defaultMessage="8 Гб оперативной памяти"
                                id="downloadAppRequirementsMinMem"
                                description="скачать"
                            />
                        </dd>
                        <dd className={classes['download-options__requirement']}>
                            <FormattedMessage
                                defaultMessage="10 Гб свободного места на жестком диске"
                                id="downloadAppRequirementsMinSpaceMac"
                                description="скачать"
                            />
                        </dd>
                    </div>
                </dl>
                <Typography gutterBottom>
                    <FormattedMessage
                        defaultMessage="В текущий момент приложение находится на стадии допуска в App Store. Доступ можно получить из тестовой платформы. Для этого скачайте в App Store приложение {testFlightLink} и установите его. После этого полностью рабочая версия WattAttack будет доступна по ссылке: {appLink}."
                        id="downloadApp_dqw83fs"
                        description="скачать"
                        values={{
                            testFlightLink: <a href="https://apps.apple.com/us/app/testflight/id899247664">TestFlight</a>,
                            appLink: <a href="https://testflight.apple.com/join/JJHZxH2n">https://testflight.apple.com/join/JJHZxH2n</a>
                        }}
                    />
                </Typography>
                <Typography>
                    <FormattedMessage
                        defaultMessage="Также можно скачать установочный {pkg} архив по ссылке {pkgLink} и установить приложение самостоятельно."
                        id="downloadApp_dgf43fs"
                        description="скачать"
                        values={{
                            pkg: <b>pkg</b>,
                            pkgLink: <a href="https://download.wattattack.ru/release/WattAttack-Shipping.pkg">https://download.wattattack.ru/release/WattAttack-Shipping.pkg</a>
                        }}
                    />
                </Typography>
            </div>
            <div className={classes['download-options__details']}>
                <Link
                    className={classes['download-options__link']}
                    href={APP_DOWNLOAD_LINK_MAC}
                    variant="h2"
                    color="info.main"
                    download
                >
                    <FormattedMessage
                        defaultMessage="Скачать"
                        id="downloadAppLink"
                        description="скачать"
                    />{' '}
                    <DownloadIcon className={classes['download-options__icon']} />
                </Link>
                <Typography variant="h4" component="p">
                    {CURRENT_APP_VERSION.macos.size}{' '}
                    <FormattedMessage
                        defaultMessage="Мб"
                        id="downloadAppMb"
                        description="скачать"
                    />
                    ,{' '}
                    <FormattedMessage
                        defaultMessage="версия"
                        id="downloadAppVersion"
                        description="скачать"
                    />{' '}
                    {CURRENT_APP_VERSION.macos.version}
                </Typography>
            </div>
        </div>
    );
}

export default DownloadMac;
