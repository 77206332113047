import { BaseSyntheticEvent, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useStores } from '../../../../root-store-context';
import useLocale from '../../../../CustomHooks/useLocale';
import { useNavigate } from 'react-router-dom';
import { NAVIGATION_ROUTES } from '../../../../const';
import UploadIcon from '@mui/icons-material/Upload';
import classes from './CabinetActivities.module.css';
import Box from '@mui/material/Box';

type Activity = {
    id: number;
    name: string | null;
    description: string | null;
    distance: number;
    movingTime: number;
    elapsedTime: number;
    fitFileId: number;
};

function CabinetActivities() {
    const [file, setFile] = useState<File | undefined>();
    const [activities, setActivities] = useState<Activity[]>([]);
    const { user } = useStores();
    const navigate = useNavigate();
    const { localeUrlPrefix } = useLocale();

    useEffect(() => {
        if (!user.isLogined) {
            navigate(`${localeUrlPrefix}${NAVIGATION_ROUTES.login}`);
        }
    }, [user.isLogined, localeUrlPrefix, navigate]);

    useEffect(() => {
        fetch('/api/v1/activities', {
            method: 'GET'
        })
            .then(async (ret) => {
                const data = await ret.json();

                if (data.activities) {
                    setActivities(data.activities);
                }
            })
            .catch(e => {
                console.error(e);
            });
    }, []);

    const handleFileChange = (e: BaseSyntheticEvent) => {
        setFile(e.target.files[0]);
    };

    const handleUploadClick = (e: BaseSyntheticEvent) => {
        if (!file) {
            return;
        }

        const formData = new FormData();
        formData.append('fitFile', file);

        fetch('/api/v1/activity/upload', {
            method: 'POST',
            body: formData
        })
            .then(async (ret) => {
                const data = await ret.json();

                if (data.activity) {
                    setActivities([data.activity, ...activities]);
                }
            })
            .catch(e => {
                console.error(e);
            });
    };

    const totalActivities = activities.length;
    const totalDistance = activities.reduce((acc, activity) => acc + activity.distance, 0);
    const totalTime = activities.reduce((acc, activity) => acc + activity.elapsedTime, 0);

    const recentActivities = activities.slice(0, 5);

    return (
        <div className={classes['cabinet-activities']}>
            <section className={classes['cabinet-settings__section']}>
                <h2 className={classes['cabinet-settings__title']}>Загрузить fit-файл</h2>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <TextField type="file" onChange={handleFileChange} />
                    <Button startIcon={<UploadIcon />} variant="contained" size='large' color="primary" onClick={handleUploadClick}>
                        Загрузить
                    </Button>
                </Box>

            </section>

            <section className={classes['cabinet-settings__section']}>
                <h2 className={classes['cabinet-settings__title']}>Обзор активностей</h2>
                <p>Количество активностей: {totalActivities}</p>
                <p>Общее расстояние: {totalDistance} м</p>
                <p>Общее время: {totalTime} сек</p>
            </section>

            <section className={classes['cabinet-settings__section']}>
                <h2 className={classes['cabinet-settings__title']}>Мои последние активности</h2>
                <div className={classes['cabinet-activities__list']}>
                    {recentActivities.length === 0 && (
                        <p>У вас пока нет загруженных активностей.</p>
                    )}
                    {recentActivities.map((activity) => (
                        <div key={activity.id} className={classes['cabinet-activities__item']}>
                            <h3>Активность #{activity.id}</h3>
                            <p>Дистанция: {activity.distance} м</p>
                            <p>Время движения: {activity.movingTime} сек</p>
                            <p>Общее время: {activity.elapsedTime} сек</p>
                            {activity.name && <p>Название: {activity.name}</p>}
                            {activity.description && <p>Описание: {activity.description}</p>}
                        </div>
                    ))}
                </div>
            </section>
        </div>
    );
}

export default observer(CabinetActivities);
