import classes from './Socials.module.css';
import { visuallyHidden } from '@mui/utils';
import TelegramIcon from '@mui/icons-material/Telegram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { Typography } from '@mui/material';

function StravaIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M15.387 17.944l-2.089-4.116h-3.065L15.387 24l5.15-10.172h-3.066m-7.008-5.599l2.836 5.598h4.172L10.463 0l-7 13.828h4.169" />
        </SvgIcon>
    );
}

type SocialsProps = {
    color?: string;
};

function Socials({ color = '#fff' }: SocialsProps) {
    return (
        <ul style={{ color: color }} className={classes.socials}>
            <li className={classes['socials__item']}>
                <a
                    className={classes['socials__link']}
                    href="https://t.me/wattattack"
                    target="_blank"
                >
                    <Typography sx={visuallyHidden}>Telegram</Typography>
                    <TelegramIcon className={classes['socials__icon']} fontSize="large" />
                </a>
            </li>
            <li className={classes['socials__item']}>
                <a
                    className={classes['socials__link']}
                    href="https://www.youtube.com/@WattAttack"
                    target="_blank"
                >
                    <Typography sx={visuallyHidden}>YouTube</Typography>
                    <YouTubeIcon className={classes['socials__icon']} fontSize="large" />
                </a>
            </li>
            <li className={classes['socials__item']}>
                <a
                    className={classes['socials__link']}
                    href="https://www.strava.com/clubs/wattattack"
                    target="_blank"
                >
                    <Typography sx={visuallyHidden}>Strava</Typography>
                    <StravaIcon className={classes['socials__icon']} fontSize="large" />
                </a>
            </li>
        </ul>
    );
}

export default Socials;
