import Typography from '@mui/material/Typography';
import CustomContainer from '../CustomContainer/CustomContainer';
import Box from '@mui/material/Box';
import MirLogo from '../../../assets/images/payments/Mir.svg';
import MasterCardLogo from '../../../assets/images/payments/Mastercard.svg';
import VisaLogo from '../../../assets/images/payments/Visa.svg';
import classes from './SubscriptionSupport.module.css';

function SubscriptionSupport() {
    return (
        <section
            className={classes['subscription-support']}
            aria-labelledby="subscription-support_title"
        >
            <CustomContainer>
                <Typography
                    variant="h1"
                    component="h1"
                    color="primary"
                    className={classes['subscription-support__title']}
                    id="subscription-support_title"
                >
                    Поддержка покупателей
                </Typography>
                <Box>
                    <Typography variant="h4" component="h2">
                        Стоимость подписки и условия оплаты
                    </Typography>
                    <Typography gutterBottom>
                        Стоимость подписки указана на нашем сервисе и включает все необходимые
                        расходы, связанные с предоставлением услуг, если иное не предусмотрено
                        Офертой.
                        <br />
                        Оплата подписки осуществляется безналичным расчетом, и её размер не
                        изменяется в зависимости от количества использованных услуг в период
                        действия подписки. Точная сумма определяется выбранным тарифным планом и
                        указывается на странице оплаты.
                        <br />
                        Исполнитель имеет право в одностороннем порядке изменять стоимость подписки,
                        но это не затрагивает уже оплаченные периоды действия подписки.
                        <br />
                        Все расчеты производятся в рублях Российской Федерации, согласно условиям
                        договора.
                    </Typography>
                    <Typography variant="h4" component="h2">
                        Оплата картами
                    </Typography>
                    <Typography>
                        Оплата производится через авторизационный сервер банка, поддерживающий
                        следующие платежные системы:
                    </Typography>
                    <Box sx={{ display: 'grid', gap: '65px', mt: 4, mb: 4 }}>
                        <img width={320} alt='Логотип платёжной системы Visa.' src={VisaLogo} />
                        <img width={320} alt='Логотип платёжной системы MasterCard.' src={MasterCardLogo} />
                        <img width={320} alt='Логотип платёжной системы Мир.' src={MirLogo} />
                    </Box>
                    <Typography>
                        Для проведения платежа вам потребуется ввести данные вашей банковской карты.
                        Передача информации осуществляется через защищенное соединение (протокол
                        TLS) и сохраняется на специализированных серверах платёжной системы. Наш
                        сайт и магазин не сохраняют и не имеют доступа к данным вашей карты.
                    </Typography>
                    <Typography gutterBottom>
                        При оплате картой, платеж будет произведен сразу после оформления заказа.
                        После подтверждения заказа вы будете перенаправлены на страницу банка для
                        ввода данных карты и завершения авторизации. После успешной авторизации вас
                        вернут на наш сайт, где вы получите уведомление о результате. В случае
                        отказа авторизации карты, вы сможете повторить попытку оплаты.
                    </Typography>
                    <Typography variant="h4" component="h2">
                        Оплата по внешней ссылке из приложения WattAttack
                    </Typography>
                    <Typography gutterBottom>
                        Этот метод оплаты безопасен и соответствует требованиям оплаты подписок для
                        приложений. После успешного платежа подписка активируется автоматически в
                        приложении.
                    </Typography>
                    <Typography variant="h4" component="h2">
                        Отмена подписки
                    </Typography>
                    <Typography gutterBottom>
                        Вы можете отменить вашу подписку в любой момент, нажат в личном кабинете кнопку «Отменить подписку». Автоматическое списание платежей с вашей карты прекратится, а доступ будет до конца последнего оплаченного периода. До окончания оплаченного периода вы можете снова восстановить подписку нажав в личном кабинете кнопку «Возобновить подписку».
                    </Typography>
                    <Typography variant="h4" component="h2">
                        Условия возврата и поддержка покупателей
                    </Typography>
                    <Typography>
                        Возможен возврат полной стоимости подписки в течение 5 банковских дней с момента оформления подписки или автоматического списания платежа за текущую подписку.<br />
                        Для возврата средств укажите напишите нам, указав ваш адрес электронной почты, на которой зарегистрирована учётная запись:{' '}
                        <a href="mailto:info@wattattack.com">info@wattattack.com</a>
                    </Typography>
                </Box>
            </CustomContainer>
        </section>
    );
}

export default SubscriptionSupport;
