import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import useLocale from '../../../CustomHooks/useLocale';
import { visuallyHidden } from '@mui/utils';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { WattAttackLogo } from '../WattAttackLogo';
import { NAVIGATION_ROUTES } from '../../../const';
import classes from './MainHero.module.css';

function MainHero() {
    const { localeUrlPrefix } = useLocale();

    return (
        <section className={classes['main-hero']} aria-labelledby="main-hero_title">
            <Typography
                component="h1"
                sx={visuallyHidden}
                className="notranslate"
                id="main-hero_title"
            >
                <FormattedMessage
                    id="Header_wkxE0t"
                    defaultMessage="WattAttack"
                    description="заголовок"
                />
            </Typography>
            <WattAttackLogo
                className={classes['main-hero__logo']}
                variant="full"
                width="735"
                height="334"
            />
            <Typography className={classes['main-hero__subtitle']} component="h2" variant='h1'>
                <FormattedMessage
                    id="Header_subtitle"
                    defaultMessage="Приложение для реалистичных велотренировок"
                    description="заголовок"
                />
            </Typography>
            <Button
                variant="contained"
                size="large"
                className={classes['main-hero__link']}
                component={Link}
                to={`${localeUrlPrefix}${NAVIGATION_ROUTES.how_to_start}`}
            >
                <FormattedMessage
                    id="Header_+Qo0OA"
                    defaultMessage="Погнали!"
                    description="кнопка в шапке"
                />
            </Button>
        </section>
    );
}

export default MainHero;
