import { observer } from 'mobx-react-lite';
import { SyntheticEvent, useEffect, useState } from 'react';

import * as yup from 'yup';

import Button from '@mui/material/Button';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import { Autocomplete, InputAdornment, Typography } from '@mui/material';
import CircularProgressWrapper from '../../../CircularProgressWrapper/CircularProgressWrapper';
import TextFieldWrapper from '../../../TextFieldWrapper/TextFieldWrapper';

import { FormattedMessage, useIntl } from 'react-intl';
import useLocale from '../../../../../CustomHooks/useLocale';
import { useStores } from '../../../../../root-store-context';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { useAlertContext } from '../../../Context/AlertContext';

import countries, { CountryType } from './countries';

import { ru } from 'date-fns/locale/ru';
import { enGB } from 'date-fns/locale/en-GB';
import { NAVIGATION_ROUTES } from '../../../../../const';

import { useValidationSchema } from '../../../../../CustomHooks/useValidationSchema';
import classes from '../CabinetSettings.module.css';

const currentDate = new Date();
const tenYearsAgoDate = new Date(
    currentDate.getFullYear() - 10,
    currentDate.getMonth(),
    currentDate.getDate()
);

function birthDateToDate(birthDate: string): Date {
    const parts = birthDate.split('-');
    const yyyy = parts[0];
    const mm = parts[1];
    const dd = parts[2];

    return new Date(parseInt(yyyy, 10), parseInt(mm, 10) - 1, parseInt(dd, 10));
}

function dateToBirthDate(date: Date): string {
    const yyyy = date.getFullYear();
    const m = date.getMonth() + 1;
    const mm = m > 9 ? `${m}` : `0${m}`;
    const d = date.getDate();
    const dd = d > 9 ? `${d}` : `0${d}`;

    return `${yyyy}-${mm}-${dd}`;
}

const CabinetSettingsAthleteProfileForm = () => {
    const intl = useIntl();
    const navigate = useNavigate();
    const showAlert = useAlertContext();
    const { heightSchema, weightSchema, birthDateSchema } = useValidationSchema();

    const { localeUrlPrefix } = useLocale();
    const { user, athlete } = useStores();
    const athleteData = athlete.data;

    const [showErrors, setShowErrors] = useState(false);
    const handleClickShowErrors = () => {
        setShowErrors(true);
    };

    useEffect(() => {
        if (user.isLogined) {
            athlete.get();
        } else {
            navigate(`${localeUrlPrefix}${NAVIGATION_ROUTES.login}`);
        }
    }, []);

    const formik = useFormik({
        initialValues: {
            birthDate: null as Date | null,
            gender: null as string | null,
            height: '' as string,
            weight: '' as string,
            sport: '' as string,
            country: null as CountryType | null
        },
        onSubmit: async (values) => {
            await athlete.update({
                birthDate: values.birthDate && dateToBirthDate(values.birthDate),
                gender: values.gender,
                height: (values.height && Number(values.height)) || null,
                sport: values.sport || null,
                weight: (values.weight && Number(values.weight)) || null,
                country: values.country && values.country.code
            });

            if (athlete.state === 'ok') {
                showAlert(
                    intl.formatMessage({
                        defaultMessage: 'Данные обновлены!',
                        id: 'Cabinet_PersonalChangeSuccess',
                        description: 'кабинет'
                    }),
                    'success'
                );
            } else {
                showAlert(
                    intl.formatMessage({
                        defaultMessage: 'Произошла ошибка.',
                        id: 'Cabinet_PersonalChangeError',
                        description: 'кабинет'
                    }),
                    'error'
                );
            }
        },
        validationSchema: yup.object({
            height: heightSchema,
            weight: weightSchema,
            birthDate: birthDateSchema
        })
    });

    const { isSubmitting } = formik;

    useEffect(() => {
        if (athleteData) {
            formik.setValues({
                birthDate: athleteData.birthDate ? birthDateToDate(athleteData.birthDate) : null,
                gender: athleteData.gender || null,
                sport: athleteData.sport || '',
                weight: athleteData.weight ? String(athleteData.weight) : '',
                height: athleteData.height ? String(athleteData.height) : '',
                country:
                    (athleteData.country &&
                        countries.find((country) => country.code === athleteData.country)) ||
                    null
            });
        }
    }, [athleteData]);

    const handleBirthDayChange = (value: Date | null) => {
        formik.setFieldValue('birthDate', value);
    };

    const handleCountryChange = (e: SyntheticEvent, value: CountryType | null) => {
        formik.setFieldValue('country', value);
    };
    return (
        <div>
            <form
                onSubmit={formik.handleSubmit}
                className={classes['cabinet-settings__form']}
                aria-label={intl.formatMessage({
                    defaultMessage: 'Форма настроек пользователя',
                    id: 'Cabinet_personalSettingsFormTitle',
                    description: 'кабинет'
                })}
            >
                <Typography
                    variant="h4"
                    component="h3"
                    className={classes['cabinet-settings__subtitle']}
                >
                    <FormattedMessage
                        defaultMessage="Настройки пользователя"
                        id="Cabinet_PersonalSectionSubtitle2"
                        description="кабинет"
                    />
                </Typography>

                <FormControl>
                    <FormLabel id="cabinet-settings-profile-gender-label">
                        <FormattedMessage
                            defaultMessage="Пол"
                            id="Cabinet_PersonalSectionGender"
                            description="кабинет"
                        />
                    </FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="cabinet-settings-profile-gender-label"
                        name="gender"
                        value={formik.values.gender}
                        onChange={formik.handleChange}
                    >
                        <FormControlLabel
                            label={intl.formatMessage({
                                defaultMessage: 'Женский',
                                id: 'Cabinet_PersonalSectionGenderFemale',
                                description: 'кабинет'
                            })}
                            value="female"
                            control={<Radio />}
                        />
                        <FormControlLabel
                            value="male"
                            control={<Radio />}
                            label={intl.formatMessage({
                                defaultMessage: 'Мужской',
                                id: 'Cabinet_PersonalSectionGenderMale',
                                description: 'кабинет'
                            })}
                        />
                    </RadioGroup>
                </FormControl>

                <TextFieldWrapper
                    errorText={formik.errors.birthDate}
                    errorId="birthDateError"
                    showErrors={showErrors}
                >
                    <FormControl>
                        <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            adapterLocale={intl.locale === 'ru' ? ru : enGB}
                        >
                            <DatePicker
                                label={intl.formatMessage({
                                    defaultMessage: 'Дата рождения',
                                    id: 'Cabinet_PersonalSectionBirthDate',
                                    description: 'кабинет'
                                })}
                                name="birthDate"
                                value={formik.values.birthDate}
                                onChange={handleBirthDayChange}
                                maxDate={tenYearsAgoDate}
                            />
                        </LocalizationProvider>
                    </FormControl>
                </TextFieldWrapper>

                <TextFieldWrapper>
                    <FormControl>
                        <InputLabel
                            id="cabinet-settings-profile-sport-label"
                            htmlFor="cabinet-settings-profile-sport"
                        >
                            <FormattedMessage
                                defaultMessage="Спорт"
                                id="Cabinet_PersonalSectionSport"
                                description="кабинет"
                            />
                        </InputLabel>
                        <Select
                            label={intl.formatMessage({
                                defaultMessage: 'Спорт',
                                id: 'Cabinet_PersonalSectionSport',
                                description: 'кабинет'
                            })}
                            labelId="cabinet-settings-profile-sport-label"
                            inputProps={{
                                id: 'cabinet-settings-profile-sport'
                            }}
                            value={formik.values.sport}
                            name="sport"
                            onChange={formik.handleChange}
                        >
                            <MenuItem value="triathlon">
                                {intl.formatMessage({
                                    defaultMessage: 'Триатлон',
                                    id: 'Cabinet_VYkG82',
                                    description: 'кабинет'
                                })}
                            </MenuItem>
                            <MenuItem value="cycling">
                                {intl.formatMessage({
                                    defaultMessage: 'Велоспорт',
                                    id: 'Cabinet_C7kfTo',
                                    description: 'кабинет'
                                })}
                            </MenuItem>
                        </Select>
                    </FormControl>
                </TextFieldWrapper>

                <TextFieldWrapper
                    errorText={formik.errors.height}
                    errorId="heightError"
                    showErrors={showErrors}
                >
                    <TextField
                        label={intl.formatMessage({
                            defaultMessage: 'Рост',
                            id: 'Cabinet_PersonalSectionHeight',
                            description: 'кабинет'
                        })}
                        id="cabinet-settings-profile-height"
                        name="height"
                        placeholder={intl.formatMessage({
                            defaultMessage: 'От 100 до 250',
                            id: 'Cabinet_PersonalSectionHeightRange',
                            description: 'кабинет'
                        })}
                        value={formik.values.height}
                        inputProps={{
                            inputMode: 'numeric',
                            'aria-describedby': 'heightError'
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <FormattedMessage
                                        defaultMessage="см"
                                        id="Cabinet_PersonalSectionCentim"
                                        description="кабинет"
                                    />
                                </InputAdornment>
                            )
                        }}
                        error={showErrors && !!formik.errors.height}
                        onChange={(event) => {
                            const filteredValue = event.target.value.replace(/[^0-9]/g, '');
                            formik.setFieldValue('height', filteredValue);
                        }}
                    />
                </TextFieldWrapper>

                {/* TODO можно сделать слайдером */}
                {/* <Grid container alignItems="center" spacing={2}>
                            <Grid item xs>
                                <Slider
                                    aria-aria-labelledby="input-slider"
                                    name="height"
                                    value={formik.values.height} 
                                    onChange={formik.handleChange}
                                    min={100}
                                    max={250}
                                    step={1}
                                    valueLabelDisplay="auto"
                                />
                            </Grid>
                            <Grid item>
                                <Input 
                                    name="height" 
                                    value={formik.values.height} 
                                    onChange={formik.handleChange}
                                    inputProps={{
                                        type: 'number',
                                        'aria-labelledby': 'input-slider',
                                        step: 1,
                                        min: 100,
                                        max: 250
                                    }}
                                />
                            </Grid>
                        </Grid> */}

                <TextFieldWrapper
                    errorText={formik.errors.weight}
                    errorId="weightError"
                    showErrors={showErrors}
                >
                    <TextField
                        label={intl.formatMessage({
                            defaultMessage: 'Вес',
                            id: 'Cabinet_PersonalSectionWeight',
                            description: 'кабинет'
                        })}
                        id="cabinet-settings-profile-weight"
                        name="weight"
                        placeholder={intl.formatMessage({
                            defaultMessage: 'От 38 до 500',
                            id: 'Cabinet_PersonalSectionWeightRange',
                            description: 'кабинет'
                        })}
                        value={formik.values.weight}
                        inputProps={{
                            inputMode: 'numeric',
                            'aria-describedby': 'weightError'
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <FormattedMessage
                                        defaultMessage="кг"
                                        id="Cabinet_PersonalSectionKilos"
                                        description="кабинет"
                                    />
                                </InputAdornment>
                            )
                        }}
                        error={showErrors && !!formik.errors.weight}
                        onChange={(event) => {
                            const filteredValue = event.target.value.replace(/[^0-9]/g, '');
                            formik.setFieldValue('weight', filteredValue);
                        }}
                    />
                </TextFieldWrapper>

                <TextFieldWrapper>
                    <FormControl>
                        <Autocomplete
                            id="cabinet-settings-profile-country"
                            options={countries}
                            value={formik.values.country}
                            onChange={handleCountryChange}
                            autoHighlight
                            getOptionLabel={(option) =>
                                intl.locale === 'ru' ? option.name_ru : option.name
                            }
                            renderOption={(props, option) => (
                                <Box
                                    component="li"
                                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                    {...props}
                                >
                                    <img
                                        loading="lazy"
                                        width="20"
                                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                        alt="Flag."
                                    />
                                    {intl.locale === 'ru' ? option.name_ru : option.name}
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={intl.formatMessage({
                                        defaultMessage: 'Страна',
                                        id: 'Cabinet_PersonalSectionCountry',
                                        description: 'кабинет'
                                    })}
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'off'
                                    }}
                                />
                            )}
                        />
                    </FormControl>
                </TextFieldWrapper>

                <CircularProgressWrapper isSubmitting={isSubmitting} justify="start">
                    <Button
                        type="submit"
                        variant="contained"
                        className={classes['cabinet-settings__button']}
                        disabled={isSubmitting}
                        onClick={handleClickShowErrors}
                    >
                        <FormattedMessage
                            defaultMessage="Сохранить"
                            id="Cabinet_PersonalSectionSave"
                            description="кабинет"
                        />
                    </Button>
                </CircularProgressWrapper>
            </form>
        </div>
    );
};

export default observer(CabinetSettingsAthleteProfileForm);
