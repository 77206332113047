import AppLayout from '../../layouts/AppLayout/AppLayout';
import MainHeader from '../../MainHeader/MainHeader';
import MainFooter from '../../MainFooter/MainFooter';
import AppleSubscriptionPlans from '../../AppleSubscriptionPlans/AppleSubscriptionPlans';

function AppPayPage() {
    return (
        <AppLayout>
            <MainHeader variant="cabinet" />
            <main>
                <AppleSubscriptionPlans />
            </main>
            <MainFooter />
        </AppLayout>
    );
}

export default AppPayPage;