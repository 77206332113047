import React, { createContext, useContext, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';

type ViewportContextType = {
    isMobile: boolean;
};

const ViewportContext = createContext<ViewportContextType | undefined>(undefined);

export const useViewport = () => {
    const context = useContext(ViewportContext);
    if (!context) {
        throw new Error('useViewport must be used within a ViewportProvider');
    }
    return context;
};

type ViewportProviderProps = {
    children: React.ReactNode;
};

export const ViewportProvider: React.FC<ViewportProviderProps> = ({ children }) => {
    const theme = useTheme();
    const [isMobile, setIsMobile] = useState<boolean>(false);

    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            setIsMobile(width < theme.breakpoints.values.md);
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return <ViewportContext.Provider value={{ isMobile }}>{children}</ViewportContext.Provider>;
};
