import OAuthService from '../../../../services/OAuthService';
import Button from "@mui/material/Button";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { OAUTH_PROVIDERS } from '../../../../const';

function MailRuIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props} sx={{ width: '36px', height: '36px' }}>
            <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path id="logoPath" d="M16 7c-4.963 0-9 4.037-9 9s4.037 9 9 9c1.825 0 3.583-.55 5.07-1.565l-1.18-1.372A7.177 7.177 0 0 1 16 23.206c-3.973 0-7.206-3.233-7.206-7.206 0-3.973 3.233-7.206 7.206-7.206 3.973 0 7.206 3.233 7.206 7.206 0 .523-.06 1.044-.17 1.55-.231.947-.892 1.24-1.399 1.2-.5-.04-1.095-.397-1.099-1.278V16A4.544 4.544 0 0 0 16 11.462 4.544 4.544 0 0 0 11.462 16 4.544 4.544 0 0 0 16 20.54a4.524 4.524 0 0 0 3.24-1.365 2.897 2.897 0 0 0 2.494 1.375 3 3 0 0 0 1.815-.613c.542-.411.937-.997 1.159-1.704.035-.114.1-.376.1-.379A7.987 7.987 0 0 0 25 16c0-4.963-4.037-9-9-9zm-2.744 9A2.747 2.747 0 0 1 16 13.256 2.747 2.747 0 0 1 18.744 16 2.747 2.747 0 0 1 16 18.744 2.747 2.747 0 0 1 13.256 16z" fill="#ff9e00" fillRule="evenodd"></path>
            </svg>
        </SvgIcon>
    );
}

const MailRuAuthButton = () => {
    const handleOauthConnectClick = async () => {
        const { authorizeUrl } = await OAuthService.OAuthConnect(OAUTH_PROVIDERS.mailRu);

        document.location = authorizeUrl;
    };

    return (
        <Button
            onClick={handleOauthConnectClick}
            variant="contained"
            startIcon={<MailRuIcon />}
            size="large"
            sx={{ bgcolor: '#005ff9', '&:hover': { bgcolor: '#005cf1'}, textTransform: 'none', fontFamily: 'Arial', fontSize: '1.4rem' }}
        >
            Почта Mail.Ru
        </Button>
    );
};

export default MailRuAuthButton