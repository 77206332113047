import { buildApiUrl } from './api';
import type { OAuthProviders } from '../const';

type OAuthCallbackRequest = {
    provider: OAuthProviders;
    code: string,
    state: string,
}

class OAuthService {
    static async OAuthConnect(provider: OAuthProviders) {
        const connectResponse = (await fetch(buildApiUrl('/oauth/connect'), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ provider })
        }));

        if (connectResponse.status === 200) {
            return connectResponse.json();
        }

        throw Error('Unhandled response code');
    }

    static async OAuthLogin({ provider, code, state }: OAuthCallbackRequest) {
        const loginResponse = await fetch(buildApiUrl('/oauth/login'), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ provider, code, state })
        });

        const loginData = await loginResponse.json();

        if (loginResponse.ok) {
            return { loginData, status: 'ok'};
        }

        console.error("Error data:", loginData);
        return { errorData: loginData, status: 'error'};
    }
}

export default OAuthService