import { createContext, useContext, useState, ReactNode, useEffect } from 'react';

type ScrollContextType = {
    isScrolled: boolean;
    toggleScroll: () => void;
};

const ScrollContext = createContext<ScrollContextType | undefined>(undefined);

export const useScrollContext = () => {
    const context = useContext(ScrollContext);
    if (!context) {
        throw new Error('useScrollContext must be used within a ScrollProvider');
    }
    return context;
};

type ScrollProviderProps = {
    children: ReactNode;
};

export const ScrollProvider: React.FC<ScrollProviderProps> = ({ children }) => {
    const [isScrolled, setIsScrolled] = useState<boolean>(false);

    const toggleScroll = () => {
        if (window.scrollY > 200) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            toggleScroll();
        };

        window.addEventListener('scroll', handleScroll);

        // Cleanup
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <ScrollContext.Provider value={{ isScrolled, toggleScroll }}>
            {children}
        </ScrollContext.Provider>
    );
};
