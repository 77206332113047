import { useEffect } from 'react';
import useLocale from '../../../../CustomHooks/useLocale';
import { useStores } from '../../../../root-store-context';
import { useNavigate } from 'react-router-dom';
import AppLayout from '../../layouts/AppLayout/AppLayout';
import MainHeader from '../../MainHeader/MainHeader';
import LoginForm from '../../Auth/LoginForm/LoginForm';
import MainFooter from '../../MainFooter/MainFooter';
import { NAVIGATION_ROUTES } from '../../../../const';

function LoginPage() {
    const { user } = useStores();
    const { localeUrlPrefix } = useLocale();
    const navigate = useNavigate();

    useEffect(() => {
        if (user.isLogined) {
            navigate(`${localeUrlPrefix}${NAVIGATION_ROUTES.cabinet}`);
        }
    }, [user]);

    return (
        <AppLayout>
            <MainHeader variant="cabinet" />
            <main>
                <LoginForm />
            </main>
            <MainFooter />
        </AppLayout>
    );
}

export default LoginPage;
