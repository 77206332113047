import CircularProgress from '@mui/material/CircularProgress';
import classes from './FullPageSpinner.module.css';

export default function FullPageSpinner() {
    return (
        <div className={classes['full-page-spinner']}>
            <CircularProgress size={80} color="primary" />
        </div>
    );
}
