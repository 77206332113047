import { useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';
import CookieIcon from '@mui/icons-material/Cookie';
import classes from './CookieConsent.module.css';

function CookieConsent() {
    const [consent, setConsent] = useState(localStorage.getItem('cookieConsent'));

    const handleAccept = () => {
        localStorage.setItem('cookieConsent', 'true');
        setConsent('true');
    };

    if (consent === 'true') {
        return null;
    }

    return (
        <Snackbar
            open={!consent}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            className={classes.cookies}
        >
            <Alert
                icon={<CookieIcon />}
                elevation={4}
                severity='info'
                sx={{backgroundColor: '#fff', '& .MuiAlert-action': { paddingTop: '0' } }}
                className={classes['cookies__message']}
                action={
                    <Button color="info" variant="outlined" onClick={handleAccept}>
                        Ok
                    </Button>
                }
            >
                <Typography component="span" variant="subtitle2">
                    <FormattedMessage
                        defaultMessage="Мы используем cookies."
                        id="Cookies_message"
                        description="кукиз"
                    />
                </Typography>
            </Alert>
        </Snackbar>
    );
}

export default CookieConsent;
