import { useState } from 'react';
import { useStores } from '../../../root-store-context';
import { useAlertContext } from '../Context/AlertContext';
import useLocale from '../../../CustomHooks/useLocale';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CustomContainer from '../CustomContainer/CustomContainer';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

import MirLogo from '../../../assets/images/payments/Mir.svg';
import MastercardLogo from '../../../assets/images/payments/Mastercard.svg';
import VisaLogo from '../../../assets/images/payments/Visa.svg';
import { NAVIGATION_ROUTES } from '../../../const';
import classes from './AppleSubscriptionPlans.module.css';

type SubscriptionType = 'MTH' | 'YEAR' | 'TEST';

function AppleSubscriptionPlans() {
    const { user } = useStores();
    const { localeUrlPrefix } = useLocale();
    const showAlert = useAlertContext();

    const [open, setOpen] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState<{
        type: SubscriptionType;
        name: string;
        price: string;
    } | null>(null);
    const [email, setEmail] = useState('');
    const [agreed, setAgreed] = useState(false);

    const handleOpenModal = (type: SubscriptionType, name: string, price: string) => {
        setSelectedPlan({ type, name, price });
        setOpen(true);
    };

    const handleCloseModal = () => {
        setOpen(false);
        setEmail('');
        setAgreed(false);
    };

    const handleSubscribe = async () => {
        if (!email) {
            showAlert('Пожалуйста, введите адрес электронной почты', 'error');
            return;
        }

        if (!agreed) {
            showAlert('Необходимо согласиться с условиями оферты', 'error');
            return;
        }

        showAlert('Оплата прошла успешно!', 'success');
        handleCloseModal();
    };

    const handleUserSubscribe = async () => {
        showAlert('Оплата прошла успешно!', 'success');
    };

    return (
        <section
            className={classes['apple-subscription-plans']}
            aria-labelledby="apple-subscription-plans_title"
        >
            <CustomContainer>
                <Typography
                    variant="h1"
                    component="h1"
                    color="primary"
                    className={classes['apple-subscription-plans__title']}
                    id="apple-subscription-plans_title"
                >
                    Тарифные планы WattAttack
                </Typography>
                <Box>
                    <Typography mt={2}>Сейчас у вас нет активных подписок.</Typography>
                    <Typography mb={4}>
                        Оформляя подписку, вы соглашаетесь с офертой и автоматическим списанием
                        средств с вашей карты. Вы сможете отменить подписку в любой момент после её
                        оформления. Приложение будет доступно до конца оплаченного по подписке
                        периода.
                    </Typography>
                </Box>

                <Box className={classes['apple-subscription-plans__list']}>
                    <Card elevation={4} className={classes['apple-subscription-plans__item']}>
                        <Typography variant="h3">Месяц</Typography>
                        <Typography variant="subtitle2">1 000 ₽</Typography>
                        <Typography sx={{ textAlign: 'center' }}>
                            30 дней доступа к приложению. Платёж будет списываться каждый месяц.
                        </Typography>
                        <Button
                            onClick={() =>
                                user.isLogined
                                    ? handleUserSubscribe()
                                    : handleOpenModal('MTH', 'Месяц', '1 000 ₽')
                            }
                            sx={{ marginBlockStart: 'auto' }}
                            variant="contained"
                        >
                            Подписаться
                        </Button>
                    </Card>
                    <Card elevation={4} className={classes['apple-subscription-plans__item']}>
                        <Typography variant="h3">Год</Typography>
                        <Typography variant="subtitle2">10 000 ₽</Typography>
                        <Typography sx={{ textAlign: 'center' }}>
                            365 дней доступа к приложению. Экономия 2 тысячи в год. Платёж будет
                            списываться каждый год.
                        </Typography>
                        <Button
                            onClick={() =>
                                user.isLogined
                                    ? handleUserSubscribe()
                                    : handleOpenModal('YEAR', 'Год', '10 000 ₽')
                            }
                            sx={{ marginBlockStart: 'auto' }}
                            variant="contained"
                        >
                            Подписаться
                        </Button>
                    </Card>
                </Box>

                <Dialog
                    open={open}
                    onClose={handleCloseModal}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                >
                    <DialogTitle>Оплата подписки</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <Typography
                                id="modal-title"
                                variant="subtitle1"
                                gutterBottom
                                sx={{ textAlign: 'left', color: 'black' }}
                            >
                                Выбранный тариф: {selectedPlan?.name}
                            </Typography>
                            <Typography
                                id="modal-description"
                                variant="subtitle1"
                                sx={{ textAlign: 'left', color: 'black' }}
                            >
                                Стоимость: {selectedPlan?.price}
                            </Typography>
                            <Typography mb={4}>
                                Введите адрес электронной почты под которым вы зарегистрированы в
                                WattAttack.
                            </Typography>
                        </DialogContentText>
                        <TextField
                            autoFocus
                            required
                            margin="dense"
                            id="name"
                            name="email"
                            label="Email"
                            type="email"
                            fullWidth
                            variant="outlined"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={agreed}
                                    onChange={(e) => setAgreed(e.target.checked)}
                                />
                            }
                            label="Я соглашаюсь с условиями оферты"
                            sx={{ mt: 2 }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            color="info"
                            size="large"
                            onClick={handleCloseModal}
                        >
                            Отмена
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            type="submit"
                            onClick={handleSubscribe}
                        >
                            Оплатить
                        </Button>
                    </DialogActions>
                </Dialog>
                <Box mb={8}>
                    <Typography variant="h3" component="h2" mb={6} sx={{ textAlign: 'center' }}>
                        Вы можете произвести оплату при помощи платёжных систем
                    </Typography>
                    <Box>
                        <img
                            src={MirLogo}
                            alt="Логотип платёжной системы Мир."
                            width="400"
                            height="120"
                        />
                        <img
                            src={MastercardLogo}
                            alt="Логотип платёжной системы MasterCard."
                            width="400"
                            height="120"
                        />
                        <img
                            src={VisaLogo}
                            alt="Логотип платёжной системы Visa."
                            width="400"
                            height="120"
                        />
                    </Box>
                </Box>
                <Box>
                    <Typography variant="subtitle1" sx={{ textAlign: 'left' }}>
                        Получить поддержку по вопросам оплаты подписки, подробную информацию о
                        тарифных планах и возвратах вы можете на странице{' '}
                        <Link to={`${localeUrlPrefix}${NAVIGATION_ROUTES.subscription_support}`}>
                            поддержки
                        </Link>{' '}
                        или по электронному адресу{' '}
                        <a href="mailto:info@wattattack.com">info@wattattack.com</a>.
                    </Typography>
                </Box>
            </CustomContainer>
        </section>
    );
}

export default AppleSubscriptionPlans;
