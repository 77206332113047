import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../root-store-context';
import { FormattedMessage } from 'react-intl';
import useLocale from '../../../CustomHooks/useLocale';
import { Typography } from '@mui/material';
import CustomContainer from '../CustomContainer/CustomContainer';
import CabinetSettings from './CabinetSettings/CabinetSettings';
import { NAVIGATION_ROUTES } from '../../../const';
import classes from './Cabinet.module.css';

const Cabinet = () => {
    const { localeUrlPrefix } = useLocale();
    const { user } = useStores();

    const navigate = useNavigate();

    useEffect(() => {
        if (!user.isLogined) {
            navigate(`${localeUrlPrefix}${NAVIGATION_ROUTES.login}`);
        }
    }, []);

    return (
        <section className={classes.cabinet} aria-labelledby="cabinet_title">
            <CustomContainer>
                <Typography
                    variant="h1"
                    component="h1"
                    color="primary"
                    className={classes['cabinet__title']}
                    id="cabinet_title"
                >
                    <FormattedMessage
                        defaultMessage="Кабинет пользователя"
                        id="Cabinet_title"
                        description="кабинет"
                    />
                </Typography>
                <CabinetSettings />
            </CustomContainer>
        </section>
    );
};

export default observer(Cabinet);
