import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { FormattedMessage } from 'react-intl';
import { useStores } from '../../../../root-store-context';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import CabinetSettingsAthleteProfileForm from './CabinetSettingsAthleteProfileForm/CabinetSettingsAthleteProfileForm';
import CabinetSettingsUserProfileForm from './CabinetSettingsUserProfileForm/CabinetSettingsUserProfileForm';
import CabinetSettingsUserEmailForm from './CabinetSettingsUserEmailForm/CabinetSettingsUserEmailForm';
import CabinetSettingsUserPasswordForm from './CabinetSettingsUserPasswordForm/CabinetUserPasswordForm';
import CabinetSettingsSubscriptionStatus from './CabinetSettingsSubscriptionStatus/CabinetSettingsSubscriptionStatus';
import CabinetSettingsConnections from './CabinetSettingsConnections/CabinetSettingsConnections';
import CabinetUserSetPasswordForm from './CabinetUserSetPasswordForm/CabinetUserSetPasswordForm';
import classes from './CabinetSettings.module.css';
import CabinetActivities from '../CabinetActivities/CabinetActivities';
import CabinetSettingsSubscriptionStatusNotConfirmed from './CabinetSettingsSubscriptionStatusNotConfirmed/CabinetSettingsSubscriptionStatusNotConfirmed';


const tabHashMap = {
    "1": "profile",
    "2": "subscription",
    "3": "activities"
} as const;

const reverseTabHashMap = Object.entries(tabHashMap).reduce((acc, [key, value]) => {
    acc[value] = key as keyof typeof tabHashMap;
    return acc;
}, {} as Record<string, keyof typeof tabHashMap>);

const CabinetSettings = () => {
    const { user } = useStores();
    const userData = user.data;

    const initialTabIndex = reverseTabHashMap[window.location.hash.replace('#', '')] || "1";
    const [tabIndex, setTabIndex] = useState<keyof typeof tabHashMap>(initialTabIndex);

    const [isEmailEditorMode, setIsEmailEditorMode] = useState<boolean>(false);
    const [isPasswordEditorMode, setIsPasswordEditorMode] = useState<boolean>(false);
    const [isSetPasswordShowed, setIsSetPasswordShowed] = useState<boolean>(
        userData?.needsPasswordReset === true
    );

    const handleTabIndexChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabIndex(newValue as keyof typeof tabHashMap);
    };

    const handleEmailEditClick = () => {
        setIsEmailEditorMode((prev) => !prev);
    };

    const handlePasswordEditClick = () => {
        setIsPasswordEditorMode((prev) => !prev);
    };

    useEffect(() => {
        window.location.hash = tabHashMap[tabIndex];
    }, [tabIndex]);

    useEffect(() => {
        const currentHash = window.location.hash.replace('#', '');
        const tabValue = Object.keys(tabHashMap).find(key => tabHashMap[key as keyof typeof tabHashMap] === currentHash);
        if (tabValue) {
            setTabIndex(tabValue as keyof typeof tabHashMap);
        }
    }, []);

    return (
        <div className={classes['cabinet-settings']}>
            {!userData?.isActivated && <CabinetSettingsSubscriptionStatusNotConfirmed />}
            <TabContext value={tabIndex}>
                <TabList onChange={handleTabIndexChange} aria-label="Разделы личного кабинета">
                    <Tab label="Профиль" value="1" />
                    <Tab label="Подписка" value="2" />
                    <Tab label="Активности" value="3" />
                </TabList>
                <TabPanel value="1">
                    <section
                        className={classes['cabinet-settings__section']}
                        aria-labelledby="account_title"
                    >
                        <Typography
                            variant="h3"
                            component="h2"
                            className={classes['cabinet-settings__title']}
                            id="account_title"
                        >
                            <FormattedMessage
                                defaultMessage="Учётная запись"
                                id="Cabinet_AccountSectionTitle"
                                description="Кабинет"
                            />
                        </Typography>
                        <p className={classes['cabinet-settings__text']}>
                            <FormattedMessage
                                defaultMessage="Здесь вы можете поменять адрес электронной почты (который также является логином) и пароль."
                                id="Cabinet_AccountSectionDesc"
                                description="Кабинет"
                            />
                        </p>
                        <div className={classes['cabinet-settings__forms']}>
                            {isEmailEditorMode && (
                                <CabinetSettingsUserEmailForm
                                    setIsEditorMode={setIsEmailEditorMode}
                                />
                            )}
                            {isPasswordEditorMode && (
                                <CabinetSettingsUserPasswordForm
                                    setIsEditorMode={setIsPasswordEditorMode}
                                />
                            )}
                            {isSetPasswordShowed && (
                                <CabinetUserSetPasswordForm
                                    setIsEditorMode={setIsSetPasswordShowed}
                                />
                            )}
                            <div
                                className={`${classes['cabinet-settings__buttons']} ${classes['cabinet-settings__buttons--offset']}`}
                            >
                                {!isEmailEditorMode && (
                                    <Button
                                        type="button"
                                        onClick={handleEmailEditClick}
                                        className={classes['cabinet-settings__button']}
                                        variant="contained"
                                    >
                                        <FormattedMessage
                                            defaultMessage="Изменить email"
                                            id="Cabinet_EmailChange"
                                            description="кабинет"
                                        />
                                    </Button>
                                )}

                                {!isPasswordEditorMode && !isSetPasswordShowed && (
                                    <Button
                                        type="button"
                                        onClick={handlePasswordEditClick}
                                        className={classes['cabinet-settings__button']}
                                        variant="contained"
                                    >
                                        <FormattedMessage
                                            defaultMessage="Изменить пароль"
                                            id="Cabinet_PasswordChangeChange"
                                            description="кабинет"
                                        />
                                    </Button>
                                )}
                            </div>
                        </div>
                    </section>
                    <section
                        className={classes['cabinet-settings__section']}
                        aria-labelledby="personal-data_title"
                    >
                        <Typography
                            variant="h3"
                            component="h2"
                            className={classes['cabinet-settings__title']}
                            id="personal-data_title"
                        >
                            <FormattedMessage
                                defaultMessage="Личные данные"
                                id="Cabinet_PersonalSectionTitle"
                                description="Кабинет"
                            />
                        </Typography>
                        <p className={classes['cabinet-settings__text']}>
                            <FormattedMessage
                                defaultMessage="Настройки пользователя в игре. Вы можете их поменять также на странице пользователя в приложении WattAttack."
                                id="Cabinet_PersonalSectionDesc"
                                description="Кабинет"
                            />
                        </p>
                        <div className={classes['cabinet-settings__forms']}>
                            <CabinetSettingsUserProfileForm />
                            <CabinetSettingsAthleteProfileForm />
                        </div>
                    </section>
                    <section
                        className={classes['cabinet-settings__section']}
                        aria-labelledby="connections_title"
                    >
                        <Typography
                            variant="h3"
                            component="h2"
                            className={classes['cabinet-settings__title']}
                            id="connections_title"
                        >
                            <FormattedMessage
                                defaultMessage="Подключённые сервисы"
                                id="Cabinet_ConnectionsSectionTitle"
                                description="Кабинет"
                            />
                        </Typography>
                        <CabinetSettingsConnections />
                    </section>
                </TabPanel>
                <TabPanel value="2">
                    <section
                        aria-labelledby="subscription_title"
                    >
                        <Typography
                            variant="h3"
                            component="h2"
                            className={classes['cabinet-settings__title']}
                            id="subscription_title"
                        >
                            <FormattedMessage
                                defaultMessage="Подписка"
                                id="Cabinet_SubscriptionSectionTitle"
                                description="кабинет"
                            />
                        </Typography>
                        <CabinetSettingsSubscriptionStatus />
                    </section>
                </TabPanel>
                <TabPanel value="3">
                    <CabinetActivities />
                </TabPanel>
            </TabContext>
        </div>
    );
};

export default observer(CabinetSettings);
