import { buildApiUrl } from './api';

export type Athlete = {
    weight: number;
    height: number;
    sport: string;
    gender: string;
    country: string;
    birthDate: string;
};

type ErrorResponse = {
    code: string;
};

type FaultResponse = {
    message: string;
    errors: ErrorResponse[];
};

type SuccessfulGetBody = {
    athlete: Athlete;
};

type SuccessfulUpdateBody = {
    athlete: Athlete;
};

type BadRequestBody = FaultResponse;
type NotFoundBody = FaultResponse;

type AthleteServiceGetResponse =
    | (Omit<Response, 'json'> & {
          status: 200;
          json: () => SuccessfulGetBody | PromiseLike<SuccessfulGetBody>;
      })
    | (Omit<Response, 'json'> & {
          status: 404;
          json: () => NotFoundBody | PromiseLike<NotFoundBody>;
      })
    | (Omit<Response, 'json'> & {
          status: 400;
          json: () => BadRequestBody | PromiseLike<BadRequestBody>;
      });

type AthleteServiceUpdateResponse =
    | (Omit<Response, 'json'> & {
          status: 200;
          json: () => SuccessfulUpdateBody | PromiseLike<SuccessfulUpdateBody>;
      })
    | (Omit<Response, 'json'> & {
          status: 400;
          json: () => BadRequestBody | PromiseLike<BadRequestBody>;
      });

export type AthleteServiceUpdateRequest = {
    weight: number | null;
    height: number | null;
    sport: string | null;
    gender: string | null;
    country: string | null;
    birthDate: string | null;
};

class AthleteService {
    static async get() {
        const response = (await fetch(buildApiUrl('/athlete'), {
            method: 'GET'
        })) as AthleteServiceGetResponse;

        if (response.status === 200 || response.status === 404) {
            return response.json();
        }

        throw Error('Unhandled response code');
    }

    static async update(data: AthleteServiceUpdateRequest) {
        const response = (await fetch(buildApiUrl('/athlete/update'), {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })) as AthleteServiceUpdateResponse;

        if (response.status === 200 || response.status === 400) {
            return response.json();
        }

        throw Error('Unhandled response code');
    }
}

export default AthleteService;
