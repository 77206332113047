import { FormattedMessage } from 'react-intl';
import CustomContainer from '../CustomContainer/CustomContainer';
import Typography from '@mui/material/Typography';
import classes from './About.module.css';

export type ratesDataType = {
    title: string;
    button: string;
    price: string;
    is_enable: boolean;
};

const About = () => {
    return (
        <section id="about" className={classes['about']} aria-labelledby="about_title">
            <CustomContainer>
                <Typography
                    className={classes['about__title']}
                    variant="h2"
                    component="h2"
                    color="primary"
                    id="about_title"
                >
                    <FormattedMessage
                        defaultMessage="О нас"
                        id="About_d8au54"
                        description="о нас"
                    />
                </Typography>
                <div className={classes['about__content']}>
                    <Typography variant="h3" component="h3">
                        <FormattedMessage
                            defaultMessage="Кто мы?"
                            id="About_aA9P4a"
                            description="о нас"
                        />
                    </Typography>
                    <p className={classes['about__text']}>
                        <FormattedMessage
                            defaultMessage="Мы страстно любим велоспорт и триатлон, это существенная часть нашей жизни. Иногда нам кажется, что это и есть жизнь. В 2022 году наша команда собралась, чтобы сделать лучший велосимулятор для людей, в которых мы верим, — для вас!"
                            id="About_l5vgIX"
                            description="о нас"
                        />
                    </p>
                    <p className={classes['about__text']}>
                        <FormattedMessage
                            defaultMessage="Мы уверены, что нам под силу сделать лучшее приложение в мире. Это значит, что у нас с вами добавится еще один повод по-настоящему гордиться нашей страной, в которой живут талантливые, спортивные и открытые миру люди"
                            id="About_l6vgIX"
                            description="о нас"
                        />
                    </p>
                    <p className={classes['about__text']}>
                        <FormattedMessage
                            defaultMessage="Мы целыми днями работаем над новыми функциями. При выходе новых версий, обновления будут загружаться автоматически. Вы можете следить за нашим прогрессом в нашем"
                            id="About_/2LNRo"
                            description="о нас"
                        />
                        <a
                            className={classes['about__link']}
                            href="https://t.me/wattattack"
                            target="_blank"
                        >
                            <FormattedMessage
                                defaultMessage="Telegram канале."
                                id="About_/3LNRo"
                                description="о нас"
                            />
                        </a>
                    </p>

                    <Typography sx={{ breakBefore: 'column' }} variant="h3" component="h3" mt={6}>
                        <FormattedMessage
                            defaultMessage="Чем мы отличаемся?"
                            id="About_3+ebK4"
                            description="о нас"
                        />
                    </Typography>
                    <p className={classes['about__text']}>
                        <FormattedMessage
                            defaultMessage="Чтобы иметь право так говорить, мы внимательно изучили конкурентов и придумали около 30 уникальных функций нашего приложения, которые выведут тренировочный процесс на новый уровень и сделают его максимально комфортным и, главное, интересным для вас."
                            id="About_+uHE8k"
                            description="о нас"
                        />
                    </p>
                    <p className={classes['about__text']}>
                        <FormattedMessage
                            defaultMessage="Наше приложение уже сейчас имеет ряд уникальных особенностей:"
                            id="About_+uHE9k"
                            description="о нас"
                        />
                    </p>
                    <ul className={classes['about__list']}>
                        <li className={classes['about__list-item']}>
                            <p className={classes['about__list-text']}>
                                <FormattedMessage
                                    defaultMessage="отличное качество графики при низких требованиях"
                                    id="About_+uHE1k"
                                    description="о нас"
                                />
                            </p>
                        </li>
                        <li className={classes['about__list-item']}>
                            <p className={classes['about__list-text']}>
                                <FormattedMessage
                                    defaultMessage="более 30 вариантов велоформы реальных команд и клубов с возможностью добавить свою"
                                    id="About_+uHE3k"
                                    description="о нас"
                                />
                            </p>
                        </li>
                        <li className={classes['about__list-item']}>
                            <p className={classes['about__list-text']}>
                                <FormattedMessage
                                    defaultMessage="цифровые копии существующих популярных маршрутов"
                                    id="About_+uHE4k"
                                    description="о нас"
                                />
                            </p>
                        </li>
                    </ul>
                </div>
            </CustomContainer>
        </section>
    );
};

export default About;
