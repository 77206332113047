import AppLayout from "../../layouts/AppLayout/AppLayout";
import MainFooter from "../../MainFooter/MainFooter";
import MainHeader from "../../MainHeader/MainHeader";
import Support from "../../Support/Support";

function SupportPage() {
    return (
        <AppLayout>
            <MainHeader variant="cabinet" />
            <main>
                <Support />
            </main>
            <MainFooter />
        </AppLayout>
    );
}

export default SupportPage