import { makeAutoObservable } from 'mobx';
import SubscriptionService, {
    Subscription,
    SubscriptionServiceCreateRequest
} from '../services/SubscriptionService';

// TODO здесь общий класс Store
enum State {
    pending = 'pending',
    error = 'error',
    ok = 'ok'
}

class SubscriptionStore {
    constructor() {
        makeAutoObservable(this);
    }

    state: State = State.ok;

    data?: Subscription;

    error?: string;

    async get() {
        this.state = State.pending;

        try {
            const response = await SubscriptionService.get();

            this.state = State.ok;
            this.data = response.subscription;
        } catch (e) {
            this.state = State.error;
            this.error = String(e);
        }
    }

    async create(data: SubscriptionServiceCreateRequest) {
        this.state = State.pending;

        try {
            const response = await SubscriptionService.create(data);

            if ('subscription' in response) {
                this.state = State.ok;
                this.data = response.subscription;
            } else {
                this.state = State.error;
                this.error = response.message;
            }
        } catch (e) {
            this.state = State.error;
            this.error = String(e);
        }
    }

    async createPromo(data: SubscriptionServiceCreateRequest) {
        this.state = State.pending;

        try {
            const response = await SubscriptionService.createPromo(data) as any;

            if ('subscription' in response) {
                this.state = State.ok;
                this.data = response.subscription;
            } else {
                this.state = State.error;
                this.error = response.message;
            }
        } catch (e) {
            this.state = State.error;
            this.error = String(e);
        }
    }

    async cancel() {
        this.state = State.pending;

        try {
            const response = await SubscriptionService.cancel();

            if ('subscription' in response) {
                this.state = State.ok;
            } else {
                this.state = State.error;
            }
        } catch (e) {
            this.state = State.error;
            this.error = String(e);
        }
    }

    async restart() {
        this.state = State.pending;

        try {
            const response = await SubscriptionService.restart();

            if ('subscription' in response) {
                this.state = State.ok;
            } else {
                this.state = State.error;
            }
        } catch (e) {
            this.state = State.error;
            this.error = String(e);
        }
    }

    async update(data: SubscriptionServiceCreateRequest) {
        this.state = State.pending;

        try {
            const response = await SubscriptionService.update(data);

            if ('subscription' in response) {
                this.state = State.ok;
                this.data = response.subscription;
            } else {
                this.state = State.error;
                this.error = response.message;
            }
        } catch (e) {
            this.state = State.error;
            this.error = String(e);
        }
    }
}

export default SubscriptionStore;
