import { DEFAULT_LOCALE, SUPPORTED_LOCALES } from '../const';

const LOCALE_MATCH_REGEXP = new RegExp(`^/(${SUPPORTED_LOCALES.join('|')})(?:/|$)`);

export { LOCALE_COOKIE_NAME } from '../const';

export function extractLocaleFromPath(path: string): string | null {
    const match = String(path).match(LOCALE_MATCH_REGEXP);

    return match && match[1];
}

export function urlPrefixByLocale(locale: string) {
    return locale === DEFAULT_LOCALE ? '' : `/${locale}`;
}

export function isDefaultLocale(locale: string) {
    return locale === DEFAULT_LOCALE;
}

export function getDefaultLocale() {
    return DEFAULT_LOCALE;
}

export function isSupportedLocale(locale: string) {
    return SUPPORTED_LOCALES.includes(locale);
}

export function buildPathInLocale(locale: string, path: string) {
    const match = path.match(LOCALE_MATCH_REGEXP);

    return `${urlPrefixByLocale(locale)}${match ? path.substring(match[1].length + 1) : path}`;
}

const useLocale = () => {
    // TODO: получить из объекта роутера
    const { pathname, href, origin } = document.location;
    const match = pathname.match(LOCALE_MATCH_REGEXP);
    const locale = match ? match[1] : DEFAULT_LOCALE;
    const urlWithoutLocale = match
        ? href.substring(origin.length + match[1].length + 1) || '/'
        : href.substring(origin.length);

    return {
        allLocales: SUPPORTED_LOCALES,
        defaultLocale: DEFAULT_LOCALE,
        urlPrefixByLocale,
        locale,
        localeUrlPrefix: urlPrefixByLocale(locale),
        urlWithoutLocale,
        buildUrlInLocale: (locale: string) => {
            return `${urlPrefixByLocale(locale)}${urlWithoutLocale}`;
        }
    };
};

export default useLocale;
