import { makeAutoObservable } from 'mobx';
import AthleteService, { Athlete, AthleteServiceUpdateRequest } from '../services/AthleteService';

enum State {
    pending = 'pending',
    error = 'error',
    ok = 'ok'
}

class AthleteStore {
    constructor() {
        makeAutoObservable(this);
    }

    state: State = State.ok;

    data?: Athlete;

    error?: string;

    async get() {
        this.state = State.pending;

        try {
            const response = await AthleteService.get();

            if ('athlete' in response) {
                this.state = State.ok;
                this.data = response.athlete;
            } else {
                this.state = State.error;
                this.error = response.message;
            }
        } catch (e) {
            this.state = State.error;
            this.error = String(e);
        }
    }

    async update(data: AthleteServiceUpdateRequest) {
        this.state = State.pending;

        try {
            const response = await AthleteService.update(data);

            if ('athlete' in response) {
                this.state = State.ok;
                this.data = response.athlete;
            } else {
                this.state = State.error;
                this.error = response.message;
            }
        } catch (e) {
            this.state = State.error;
            this.error = String(e);
        }
    }
}

export default AthleteStore;
