import { useEffect, useState } from 'react';
import { useStores } from '../../../../root-store-context';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import useLocale from '../../../../CustomHooks/useLocale';
import CustomContainer from '../../CustomContainer/CustomContainer';
import Typography from '@mui/material/Typography';
import {
    Alert,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    TextField
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { NAVIGATION_ROUTES } from '../../../../const';
import classes from './Registration.module.css';
import { useAlertContext } from '../../Context/AlertContext';
import CircularProgressWrapper from '../../CircularProgressWrapper/CircularProgressWrapper';
import { useValidationSchema } from '../../../../CustomHooks/useValidationSchema';
import TextFieldWrapper from '../../TextFieldWrapper/TextFieldWrapper';

const Registration = () => {
    const intl = useIntl();
    const { localeUrlPrefix } = useLocale();
    const { user } = useStores();
    const navigate = useNavigate();
    const showAlert = useAlertContext();
    const {
        emailSchema,
        passwordSchema,
        confirmPasswordSchema,
        firstNameSchema,
        lastNameSchema,
        approvalSchema
    } = useValidationSchema();

    const [showOAuthError, setShowOAuthError] = useState(false);

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    useEffect(() => {
        if (user.isLogined) {
            navigate(`${localeUrlPrefix}${NAVIGATION_ROUTES.cabinet}`);
        }

        if (user.error === 'There is not enough information in profile') {
            setShowOAuthError(true);
        }
    }, []);

    const [showErrors, setShowErrors] = useState(false);
    const handleClickShowErrors = () => {
        setShowErrors(true);
    };

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            confirmPassword: '',
            approval: false
        },
        onSubmit: async (values) => {
            await user.create({
                firstName: values.firstName,
                lastName: values.lastName || null,
                email: values.email,
                password: values.password
            });

            if (user.state === 'ok') {
                navigate(`${localeUrlPrefix}${NAVIGATION_ROUTES.cabinet}`);
            } else {
                showAlert(
                    `${intl.formatMessage({
                        defaultMessage: 'Произошла ошибка:',
                        id: 'Register_changeError',
                        description: 'форма регистрации'
                    })} ${user.error}`,
                    'error'
                );
            }
        },
        validationSchema: yup.object({
            firstName: firstNameSchema,
            lastName: lastNameSchema,
            email: emailSchema,
            password: passwordSchema,
            confirmPassword: confirmPasswordSchema,
            approval: approvalSchema
        })
    });

    const { isSubmitting } = formik;

    return (
        <section className={classes.registration} aria-labelledby="registration-title">
            <CustomContainer>
                <Typography
                    variant="h1"
                    component="h1"
                    color="primary"
                    id="registration-title"
                    className={classes['registration__title']}
                >
                    <FormattedMessage
                        defaultMessage="Регистрация"
                        id="Register_hsFzVb"
                        description="форма регистрации"
                    />
                </Typography>
                <form
                    onSubmit={formik.handleSubmit}
                    className={classes['registration__form']}
                    aria-label={intl.formatMessage({
                        defaultMessage: 'Форма регистрации',
                        id: 'Register_formTitle',
                        description: 'форма регистрации'
                    })}
                >
                    {showOAuthError && (
                        <Alert severity="error" className={classes['registration__alert']}>
                            <Typography variant='body1'>
                                <FormattedMessage
                                    defaultMessage="Мы не смогли найти в вашем профиле нужную информацию. Пожалуйста, заполните форму регистрации."
                                    id="Register_VKEmailError"
                                    description="форма регистрации"
                                />
                            </Typography>
                        </Alert>
                    )}
                    <div className={classes['registration__paired-fields']}>
                        <TextFieldWrapper
                            errorText={formik.errors.firstName}
                            errorId="nameError"
                            showErrors={showErrors}
                        >
                            <TextField
                                type="text"
                                name="firstName"
                                label={intl.formatMessage({
                                    defaultMessage: 'Имя',
                                    id: 'Register_noixy0',
                                    description: 'форма регистрации'
                                })}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.firstName}
                                error={showErrors && !!formik.errors.firstName}
                                inputProps={{
                                    'aria-describedby': 'nameError'
                                }}
                            />
                        </TextFieldWrapper>

                        <TextFieldWrapper
                            helper={intl.formatMessage({
                                defaultMessage: 'необязательно',
                                id: 'Register_9MfeLJ',
                                description: 'форма регистрации'
                            })}
                            errorText={formik.errors.lastName}
                            errorId="lastNameError"
                            showErrors={showErrors}
                        >
                            <TextField
                                type="text"
                                name="lastName"
                                label={intl.formatMessage({
                                    defaultMessage: 'Фамилия',
                                    id: 'Register_IMfeLJ',
                                    description: 'форма регистрации'
                                })}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.lastName}
                                error={showErrors && !!formik.errors.lastName}
                                inputProps={{
                                    'aria-describedby': 'lastNameError'
                                }}
                            />
                        </TextFieldWrapper>
                    </div>

                    <TextFieldWrapper
                        helper={intl.formatMessage({
                            defaultMessage:
                                'На указанный адрес придёт письмо со ссылкой для активации учётной записи.',
                            id: 'Register_xw9634g',
                            description: 'форма регистрации'
                        })}
                        errorText={formik.errors.email}
                        errorId="emailError"
                        showErrors={showErrors}
                    >
                        <TextField
                            type="email"
                            name="email"
                            autoComplete="email"
                            label={intl.formatMessage({
                                defaultMessage: 'Ваш e-mail',
                                id: 'Register_rX/4bo',
                                description: 'форма регистрации'
                            })}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                            error={showErrors && !!formik.errors.email}
                            inputProps={{
                                'aria-describedby': 'emailError'
                            }}
                        />
                    </TextFieldWrapper>

                    <TextFieldWrapper
                        helper={intl.formatMessage({
                            defaultMessage:
                                'Пароль должен содержать хотя бы одну цифру, строчную и заглавную букву и должен быть от 6 до 30 символов.',
                            id: 'Register_xwo634g',
                            description: 'форма регистрации'
                        })}
                        errorText={formik.errors.password}
                        errorId="passwordError"
                        showErrors={showErrors}
                    >
                        <FormControl variant="outlined">
                            <InputLabel htmlFor="password">
                                {intl.formatMessage({
                                    defaultMessage: 'Пароль',
                                    id: 'Register_cFLCWL',
                                    description: 'форма регистрации'
                                })}
                            </InputLabel>
                            <OutlinedInput
                                type={showPassword ? 'text' : 'password'}
                                label={intl.formatMessage({
                                    defaultMessage: 'Пароль',
                                    id: 'Register_cFLCWL',
                                    description: 'форма регистрации'
                                })}
                                name="password"
                                id="password"
                                autoCapitalize="off"
                                autoCorrect="off"
                                autoComplete="new-password"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.password}
                                error={showErrors && !!formik.errors.password}
                                inputProps={{
                                    'aria-describedby': 'passwordError'
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label={intl.formatMessage({
                                                defaultMessage: 'Показать пароль',
                                                id: 'Register_Gs+e1b7',
                                                description: 'форма'
                                            })}
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </TextFieldWrapper>

                    <TextFieldWrapper
                        errorText={formik.errors.confirmPassword}
                        errorId="passwordConfirmError"
                        showErrors={showErrors}
                    >
                        <FormControl variant="outlined">
                            <InputLabel htmlFor="confirmPassword">
                                {intl.formatMessage({
                                    defaultMessage: 'Подтверждение пароля',
                                    id: 'Register_7Jp/R0',
                                    description: 'форма регистрации'
                                })}
                            </InputLabel>
                            <OutlinedInput
                                type={showPassword ? 'text' : 'password'}
                                label={intl.formatMessage({
                                    defaultMessage: 'Подтверждение пароля',
                                    id: 'Register_7Jp/R0',
                                    description: 'форма регистрации'
                                })}
                                name="confirmPassword"
                                id="confirmPassword"
                                autoCapitalize="off"
                                autoCorrect="off"
                                autoComplete="new-password"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.confirmPassword}
                                error={showErrors && !!formik.errors.confirmPassword}
                                inputProps={{
                                    'aria-describedby': 'passwordConfirmError'
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label={intl.formatMessage({
                                                defaultMessage: 'Показать пароль',
                                                id: 'Register_Gs+e1b7',
                                                description: 'форма'
                                            })}
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </TextFieldWrapper>

                    <div>
                        <div className={classes['registration__approval']}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={formik.handleChange}
                                        name="approval"
                                        checked={formik.values.approval}
                                        aria-describedby="approvalError"
                                    />
                                }
                                label={intl.formatMessage({
                                    defaultMessage: 'Согласен на',
                                    id: 'Register_+DMr+k',
                                    description: 'форма регистрации'
                                })}
                            />

                            <a
                                href="/offer_ru.pdf"
                                target="_blank"
                                className={classes['registration__link']}
                            >
                                {intl.formatMessage({
                                    defaultMessage: 'обработку персональных данных',
                                    id: 'Register_xwo34g',
                                    description: 'форма регистрации'
                                })}
                            </a>
                        </div>
                        <TextFieldWrapper
                            errorText={formik.errors.approval}
                            showErrors={showErrors}
                        />
                    </div>

                    <CircularProgressWrapper fullWidth isSubmitting={isSubmitting}>
                        <Button
                            fullWidth
                            variant="contained"
                            type="submit"
                            size="large"
                            onClick={handleClickShowErrors}
                            disabled={isSubmitting}
                        >
                            {intl.formatMessage({
                                defaultMessage: 'Регистрация',
                                id: 'Register_hsFzVb',
                                description: 'форма регистрации'
                            })}
                        </Button>
                    </CircularProgressWrapper>
                </form>

                <div className={classes['registration__actions']}>
                    <Link
                        to={`${localeUrlPrefix}${NAVIGATION_ROUTES.login}`}
                        className={classes['registration__link']}
                    >
                        {intl.formatMessage({
                            defaultMessage: 'Уже зарегистрированы? Войти',
                            id: 'Register_MgRdlz',
                            description: 'форма регистрации'
                        })}
                    </Link>
                    <Link
                        to={`${localeUrlPrefix}${NAVIGATION_ROUTES.password_recovery}`}
                        className={classes['registration__link']}
                    >
                        {intl.formatMessage({
                            defaultMessage: 'Забыли пароль?',
                            id: 'Register_Gs+eb7',
                            description: 'форма регистрации'
                        })}
                    </Link>
                </div>
            </CustomContainer>
        </section>
    );
};

export default observer(Registration);
