import AppLayout from '../../layouts/AppLayout/AppLayout';
import MainHeader from '../../MainHeader/MainHeader';
import MainFooter from '../../MainFooter/MainFooter';
import CabinetActivities from '../../Cabinet/CabinetActivities/CabinetActivities';

function CabinetActivitiesPage() {
    return (
        <AppLayout>
            <MainHeader variant="cabinet" />
            <main>
                <CabinetActivities />
            </main>
            <MainFooter />
        </AppLayout>
    );
}

export default CabinetActivitiesPage;
