import AppLayout from '../../layouts/AppLayout/AppLayout';
import MainHeader from '../../MainHeader/MainHeader';
import Registration from '../../Auth/Registration/Registration';
import MainFooter from '../../MainFooter/MainFooter';

function RegistrationPage() {
    return (
        <AppLayout>
            <MainHeader variant="cabinet" />
            <main>
                <Registration />
            </main>
            <MainFooter />
        </AppLayout>
    );
}

export default RegistrationPage;
