import { styled } from '@mui/material/styles';
import useIntl from 'react-intl/src/components/useIntl';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import RouteIcon from '@mui/icons-material/Route';
import type { TrackType } from './TracksList';
import classes from './TrackCard.module.css';

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest
    })
}));

type TrackCardProps = {
    index: number;
    card: TrackType;
    expanded: boolean;
    onExpandClick: (index: number) => void;
};

const TrackCard: React.FC<TrackCardProps> = ({ index, card, expanded, onExpandClick }) => {
    const intl = useIntl();

    const handleExpandClick = () => {
        onExpandClick(index);
    };

    return (
        <Card className={classes['track-card']} elevation={12} key={index}>
            {card.isFeatured && (
                <div className={classes['track-card__ribbon']}>
                    <span className={classes['track-card__ribbon-text']}>{card.featured_text}</span>
                </div>
            )}
            <img
                className={classes['track-card__image']}
                width={451}
                height={340}
                alt={card.title}
                src={card.image}
                srcSet={`${card.image} 1x, ${card.image2x} 2x,`}
            />
            <CardContent className={classes['track-card__content']}>
                <Typography
                    className={classes['track-card__title']}
                    variant="h3"
                    component="h3"
                    color="primary.main"
                >
                    {card.title}
                </Typography>
                <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label={intl.formatMessage({
                        defaultMessage: 'Показать больше',
                        id: 'About_c1dd374',
                        description: 'карты'
                    })}
                >
                    <ExpandMoreIcon fontSize="large" />
                </ExpandMore>
            </CardContent>
            <Collapse
                in={expanded}
                timeout="auto"
                unmountOnExit
                className={classes['track-card__details']}
            >
                <CardContent>
                    <Typography paragraph className={classes['track-card__text']}>
                        {card.details}
                    </Typography>
                    <RouteIcon fontSize="large" color="info" />
                    <Typography paragraph className={classes['track-card__text']}>
                        {card.route_properties}
                    </Typography>
                </CardContent>
            </Collapse>
        </Card>
    );
};

export default TrackCard;
