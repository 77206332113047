import { observer } from 'mobx-react-lite';

import * as yup from 'yup';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Typography } from '@mui/material';
import CircularProgressWrapper from '../../../CircularProgressWrapper/CircularProgressWrapper';

import { FormattedMessage, useIntl } from 'react-intl';
import { useStores } from '../../../../../root-store-context';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useAlertContext } from '../../../Context/AlertContext';
import { useValidationSchema } from '../../../../../CustomHooks/useValidationSchema';

import classes from '../CabinetSettings.module.css';
import TextFieldWrapper from '../../../TextFieldWrapper/TextFieldWrapper';

const CabinetSettingsUserProfileForm = () => {
    const intl = useIntl();
    const showAlert = useAlertContext();
    const { user } = useStores();
    const { firstNameSchema, lastNameSchema } = useValidationSchema();
    const userData = user.data;

    if (!userData) {
        return '';
    }

    const [showErrors, setShowErrors] = useState(false);
    const handleClickShowErrors = () => {
        setShowErrors(true);
    };

    const formik = useFormik({
        initialValues: {
            firstName: userData.firstName,
            lastName: userData.lastName || ''
        },
        onSubmit: async (values) => {
            await user.update({
                firstName: values.firstName,
                lastName: values.lastName || null
            });

            if (user.state === 'ok') {
                showAlert(
                    intl.formatMessage({
                        defaultMessage: 'Сохранено.',
                        id: 'Cabinet_SuccessMessage',
                        description: 'кабинет'
                    }),
                    'success'
                );
            } else {
                showAlert(
                    `${intl.formatMessage({
                        defaultMessage: 'Произошла ошибка:',
                        id: 'Cabinet_ErrorMessage',
                        description: 'кабинет'
                    })} ${user.error}`,
                    'error'
                );
            }
        },
        validationSchema: yup.object({
            firstName: firstNameSchema,
            lastName: lastNameSchema
        })
    });

    const { isSubmitting } = formik;

    return (
        <div>
            <form
                onSubmit={formik.handleSubmit}
                className={classes['cabinet-settings__form']}
                aria-label={intl.formatMessage({
                    defaultMessage: 'Форма данных пользователя',
                    id: 'Cabinet_personaDataFormTitle',
                    description: 'кабинет'
                })}
            >
                <Typography
                    variant="h4"
                    component="h3"
                    className={classes['cabinet-settings__subtitle']}
                >
                    <FormattedMessage
                        defaultMessage="Данные пользователя"
                        id="Cabinet_PersonalSectionSubtitle"
                        description="кабинет"
                    />
                </Typography>

                <TextFieldWrapper
                    errorText={formik.errors.firstName}
                    errorId="firstName"
                    showErrors={showErrors}
                >
                    <TextField
                        label={intl.formatMessage({
                            defaultMessage: 'Ваше имя',
                            id: 'Register_noixy0',
                            description: 'форма регистрации'
                        })}
                        id="cabinet-settings-profile-name"
                        name="firstName"
                        value={formik.values.firstName}
                        onChange={formik.handleChange}
                        error={showErrors && !!formik.errors.firstName}
                        inputProps={{
                            'aria-describedby': 'firstNameError'
                        }}
                    />
                </TextFieldWrapper>

                <TextFieldWrapper
                    errorText={formik.errors.lastName}
                    errorId="lastNameError"
                    showErrors={showErrors}
                >
                    <TextField
                        label={intl.formatMessage({
                            defaultMessage: 'Ваша фамилия',
                            id: 'Register_IMfeLJ',
                            description: 'форма регистрации'
                        })}
                        id="cabinet-settings-profile-last-name"
                        name="lastName"
                        value={formik.values.lastName}
                        onChange={formik.handleChange}
                        error={showErrors && !!formik.errors.lastName}
                        inputProps={{
                            'aria-describedby': 'lastNameError'
                        }}
                    />
                </TextFieldWrapper>

                <CircularProgressWrapper isSubmitting={isSubmitting} justify="start">
                    <Button
                        type="submit"
                        variant="contained"
                        className={classes['cabinet-settings__button']}
                        disabled={isSubmitting}
                        onClick={handleClickShowErrors}
                    >
                        <FormattedMessage
                            defaultMessage="Сохранить"
                            id="Cabinet_PersonalSectionSave"
                            description="кабинет"
                        />
                    </Button>
                </CircularProgressWrapper>
            </form>
        </div>
    );
};
// @ts-ignore
export default observer(CabinetSettingsUserProfileForm);
