import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormik } from 'formik';
import CustomContainer from '../../../CustomContainer/CustomContainer';
import Typography from '@mui/material/Typography';
import {
    Button,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CircularProgressWrapper from '../../../CircularProgressWrapper/CircularProgressWrapper';

import * as yup from 'yup';

import UserService from '../../../../../services/UserService';
import useLocale from '../../../../../CustomHooks/useLocale';
import { useNavigate } from 'react-router-dom';
import { useValidationSchema } from '../../../../../CustomHooks/useValidationSchema';
import { useAlertContext } from '../../../Context/AlertContext';
import { NAVIGATION_ROUTES } from '../../../../../const';
import classes from './ChangePasswordForm.module.css';
import TextFieldWrapper from '../../../TextFieldWrapper/TextFieldWrapper';

const ChangePasswordForm = ({ token }: { token: string }) => {
    const intl = useIntl();
    const { localeUrlPrefix } = useLocale();
    const navigate = useNavigate();
    const { passwordSchema, confirmPasswordSchema } = useValidationSchema();
    const showAlert = useAlertContext();

    const [showPassword, setShowPassword] = useState(false);
    const [showErrors, setShowErrors] = useState(false);

    const showPasswordTitle = showPassword
        ? intl.formatMessage({
              defaultMessage: 'Скрыть пароль',
              id: 'Register_Gs+e13b7',
              description: 'форма'
          })
        : intl.formatMessage({
              defaultMessage: 'Показать пароль',
              id: 'Register_Gs+e1b7',
              description: 'форма'
          });

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const formik = useFormik({
        initialValues: {
            password: '',
            confirmPassword: ''
        },
        onSubmit: async (values) => {
            try {
                await UserService.recoveryPassword({
                    token,
                    password: values.password
                });

                navigate(`${localeUrlPrefix}${NAVIGATION_ROUTES.login}`);
            } catch (error) {
                showAlert(`${intl.formatMessage({
                    defaultMessage: 'Произошла ошибка:',
                    id: 'Register_changeError',
                    description: 'форма регистрации'
                })} ${error},
                 'error'`);
            }
        },
        validationSchema: yup.object({
            password: passwordSchema,
            confirmPassword: confirmPasswordSchema
        })
    });

    const { isSubmitting } = formik;

    const handleClickShowErrors = () => {
        setShowErrors(true);
    };

    return (
        <section className={classes['change-password']} aria-labelledby="change-password-title">
            <CustomContainer>
                <Typography
                    variant="h1"
                    component="h1"
                    color="primary"
                    id="change-password-title"
                    className={classes['change-password__title']}
                >
                    <FormattedMessage
                        defaultMessage="Изменение пароля"
                        id="ChangePassword_oehSrP"
                        description="форма изменения пароля"
                    />
                </Typography>
                <form
                    onSubmit={formik.handleSubmit}
                    className={classes['change-password__form']}
                    aria-label={intl.formatMessage({
                        defaultMessage: 'Форма смены пароля',
                        id: 'Register_changePasswordFormTitle',
                        description: 'форма регистрации'
                    })}
                >
                    <TextFieldWrapper
                        helper={intl.formatMessage({
                            defaultMessage:
                                'Пароль должен содержать хотя бы одну цифру, строчную и заглавную букву и должен быть от 6 до 30 символов.',
                            id: 'Register_xwo634g',
                            description: 'форма регистрации'
                        })}
                        errorText={formik.errors.password}
                        errorId="passwordError"
                        showErrors={showErrors}
                    >
                        <FormControl variant="outlined">
                            <InputLabel htmlFor="password">
                                {intl.formatMessage({
                                    defaultMessage: 'Новый пароль',
                                    id: 'ChangePassword_Yj7S3b',
                                    description: 'форма изменения пароля'
                                })}
                            </InputLabel>
                            <OutlinedInput
                                type={showPassword ? 'text' : 'password'}
                                label={intl.formatMessage({
                                    defaultMessage: 'Новый пароль',
                                    id: 'ChangePassword_Yj7S3b',
                                    description: 'форма изменения пароля'
                                })}
                                name="password"
                                id="password"
                                autoCapitalize="off"
                                autoCorrect="off"
                                autoComplete="new-password"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={showErrors && !!formik.errors.password}
                                inputProps={{
                                    'aria-describedby': 'passwordError'
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label={intl.formatMessage({
                                                defaultMessage: 'Показать пароль',
                                                id: 'Register_Gs+e1b7',
                                                description: 'форма'
                                            })}
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                            title={showPasswordTitle}
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </TextFieldWrapper>

                    <TextFieldWrapper
                        errorText={formik.errors.confirmPassword}
                        errorId="confirmPasswordError"
                        showErrors={showErrors}
                    >
                        <FormControl variant="outlined">
                            <InputLabel htmlFor="confirmPassword">
                                {intl.formatMessage({
                                    defaultMessage: 'Подтвердите новый пароль',
                                    id: 'ChangePassword_cbiSWe',
                                    description: 'форма изменения пароля'
                                })}
                            </InputLabel>
                            <OutlinedInput
                                type={showPassword ? 'text' : 'password'}
                                label={intl.formatMessage({
                                    defaultMessage: 'Подтвердите новый пароль',
                                    id: 'ChangePassword_cbiSWe',
                                    description: 'форма изменения пароля'
                                })}
                                name="confirmPassword"
                                id="confirmPassword"
                                autoCapitalize="off"
                                autoCorrect="off"
                                autoComplete="new-password"
                                value={formik.values.confirmPassword}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={showErrors && !!formik.errors.confirmPassword}
                                inputProps={{
                                    'aria-describedby': 'confirmPasswordError'
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label={intl.formatMessage({
                                                defaultMessage: 'Изменить видимость пароля',
                                                id: 'Register_Gs+e1b7',
                                                description: 'форма'
                                            })}
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                            title={showPasswordTitle}
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </TextFieldWrapper>

                    <CircularProgressWrapper fullWidth isSubmitting={isSubmitting}>
                        <Button
                            fullWidth
                            variant="contained"
                            type="submit"
                            size="large"
                            onClick={handleClickShowErrors}
                            disabled={isSubmitting}
                        >
                            {intl.formatMessage({
                                defaultMessage: 'Сменить',
                                id: 'ChangePassword_5i/myU',
                                description: 'форма изменения пароля'
                            })}
                        </Button>
                    </CircularProgressWrapper>
                </form>
            </CustomContainer>
        </section>
    );
};

export default ChangePasswordForm;
