import { FormattedMessage } from 'react-intl';
import CustomContainer from '../CustomContainer/CustomContainer';
import Typography from '@mui/material/Typography';
import classes from './Workouts.module.css';

const Workouts = () => {
    return (
        <section id="workouts" className={classes['workouts']} aria-labelledby="workouts_title">
            <CustomContainer>
                <Typography
                    variant="h2"
                    component={'h2'}
                    className={classes['workouts__title']}
                    id="workouts_title"
                >
                    <FormattedMessage
                        defaultMessage="Тренировки"
                        id="Training_V0hkfX"
                        description="тренировки"
                    />
                </Typography>
                <Typography
                    variant="subtitle1"
                    component="p"
                    paragraph
                    className={classes['workouts__subtitle']}
                >
                    <FormattedMessage
                        defaultMessage="WattAttack предоставляет несколько вариантов создания тренировок"
                        id="Training_4IYrcq"
                        description="тренировки"
                    />
                </Typography>
                <ul className={classes['workouts__features-list']}>
                    <li className={classes['workouts__feature']}>
                        <div className={classes['workouts__feature-counter']}></div>
                        <Typography
                            variant="h2"
                            component={'h3'}
                            className={classes['workouts__feature-title']}
                        >
                            <FormattedMessage
                                defaultMessage="Самостоятельное создание"
                                id="Training_Exdh7z"
                                description="тренировки"
                            />
                        </Typography>
                        <Typography
                            variant="subtitle2"
                            component={'p'}
                            className={classes['workouts__feature-subtitle']}
                        >
                            <FormattedMessage
                                defaultMessage="Составляйте структурные тренировки, благодаря встроенному в приложение инструменту"
                                id="Training_jAYRTl"
                                description="тренировки"
                            />
                        </Typography>
                    </li>
                    <li className={classes['workouts__feature']}>
                        <div className={classes['workouts__feature-counter']}></div>
                        <Typography
                            variant="h2"
                            component={'h3'}
                            className={classes['workouts__feature-title']}
                        >
                            <FormattedMessage
                                defaultMessage="Готовые тренировки"
                                id="Training_6zca7t"
                                description="тренировки"
                            />
                        </Typography>
                        <Typography
                            variant="subtitle2"
                            component={'p'}
                            className={classes['workouts__feature-subtitle']}
                        >
                            <FormattedMessage
                                defaultMessage="Загружайте готовые тренировки, доступные в приложении"
                                id="Training_HUphWn"
                                description="тренировки"
                            />
                        </Typography>
                    </li>
                    <li className={classes['workouts__feature']}>
                        <div className={classes['workouts__feature-counter']}></div>
                        <Typography
                            variant="h2"
                            component={'h3'}
                            className={classes['workouts__feature-title']}
                        >
                            <FormattedMessage
                                defaultMessage="Групповые тренировка"
                                id="Training_+lcr+D"
                                description="тренировки"
                            />
                        </Typography>
                        <Typography
                            variant="subtitle2"
                            component={'p'}
                            className={classes['workouts__feature-subtitle']}
                        >
                            <FormattedMessage
                                defaultMessage="Участвуйте в групповых тренировках с тренером или приглашайте друзей"
                                id="Training_RPHfQl"
                                description="тренировки"
                            />
                        </Typography>
                    </li>
                    <li className={classes['workouts__feature']}>
                        <div className={classes['workouts__feature-counter']}></div>
                        <Typography
                            variant="h2"
                            component={'h3'}
                            className={classes['workouts__feature-title']}
                        >
                            <FormattedMessage
                                defaultMessage="Загрузка из ZWO файлов"
                                id="Training_nTLeZe"
                                description="тренировки"
                            />
                        </Typography>
                        <Typography
                            variant="subtitle2"
                            component={'p'}
                            className={classes['workouts__feature-subtitle']}
                        >
                            <FormattedMessage
                                defaultMessage="Загружайте из ZWO (TrainingPeaks, Zwift) файлов уже готовые тренировки. Интеграция со Strava!"
                                id="Training_Vvb3NU"
                                description="тренировки"
                            />
                        </Typography>
                    </li>
                </ul>
                <Typography variant="h2" component={'p'} className={classes['workouts__message']}>
                    <FormattedMessage
                        defaultMessage="Загружай свои тренировки в приложения Garmin, Strava, Trainingpeaks и социальные сети, и пусть все удивятся"
                        id="Training_0x/Hcm"
                        description="тренировки"
                    />
                </Typography>
            </CustomContainer>
        </section>
    );
};

export default Workouts;
