import OAuthService from '../../../../services/OAuthService';
import IconButton from "@mui/material/IconButton";
import googleAuthButton from '../../../../assets/images/google_auth_button.svg'
import { OAUTH_PROVIDERS } from "../../../../const";
import classes from './GoogleAuthButton.module.css'

function GoogleAuthButton() {
    const handleOauthConnectClick = async () => {
        const { authorizeUrl } = await OAuthService.OAuthConnect(OAUTH_PROVIDERS.google);

        document.location = authorizeUrl;
    };

    return (
        <IconButton
            onClick={handleOauthConnectClick}
            aria-label="Sign in with Google"
            className={classes['google-button']}
        >
            <img src={googleAuthButton} width='200' height='46' alt="" aria-hidden='true'/>
        </IconButton>
    );
};

export default GoogleAuthButton