import Container from '@mui/material/Container';
import classes from './CustomContainer.module.css';

type CustomContainerProps = {
    justifyContent?: 'initial' | 'center' | 'stretch' | 'start' | 'end';
    children: React.ReactNode;
    className?: string;
};

function CustomContainer({
    justifyContent = 'initial',
    children,
    className = ''
}: CustomContainerProps) {
    const justifyContentClass = {
        initial: classes['container--initial'],
        center: classes['container--center'],
        stretch: classes['container--stretch'],
        start: classes['container--start'],
        end: classes['container--end']
    }[justifyContent];

    return (
        <Container className={`${classes.container} ${justifyContentClass} ${className}`}>
            {children}
        </Container>
    );
}

export default CustomContainer;
