import { NAVIGATION_ROUTES } from '../../../../const';
import { useNavigate } from 'react-router-dom';
import { useStores } from '../../../../root-store-context';
import useLocale from '../../../../CustomHooks/useLocale';
import { useEffect } from 'react';
import AppLayout from '../../layouts/AppLayout/AppLayout';
import FullPageSpinner from '../../FullPageSpinner/FullPageSpinner';

function LogoutPage() {
    const { localeUrlPrefix } = useLocale();
    const { user } = useStores();
    const navigate = useNavigate();

    useEffect(() => {
        if (user.isLogined) {
            user.logout();
            navigate(`${localeUrlPrefix}${NAVIGATION_ROUTES.root}`);
        } else {
            navigate(`${localeUrlPrefix}${NAVIGATION_ROUTES.root}`);
        }
    }), [user];

    return (
        <AppLayout>
            <main>
                <FullPageSpinner />
            </main>
        </AppLayout>
    );
}

export default LogoutPage;
