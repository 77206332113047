import { FormattedMessage, useIntl } from 'react-intl';
import CustomContainer from '../CustomContainer/CustomContainer';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import competition1Image from '../../../assets/images/competitions/1.webp';
import competition1Image2x from '../../../assets/images/competitions/1@2x.webp';
import competition2Image from '../../../assets/images/competitions/2.webp';
import competition2Image2x from '../../../assets/images/competitions/2@2x.webp';
import competition3Image from '../../../assets/images/competitions/3.webp';
import competition3Image2x from '../../../assets/images/competitions/3@2x.webp';
import competition4Image from '../../../assets/images/competitions/4.webp';
import competition4Image2x from '../../../assets/images/competitions/4@2x.webp';
import classes from './Competitions.module.css';

type CompetitionType = {
    image: string;
    image2x: string;
    title: string;
    details: string;
};

const Competitions = () => {
    const intl = useIntl();

    const competitions: CompetitionType[] = [
        {
            image: competition1Image,
            image2x: competition1Image2x,
            title: intl.formatMessage({
                defaultMessage: 'Настоящая дуэль',
                id: 'Competitions_eYA5UV',
                description: 'соревнования'
            }),
            details: intl.formatMessage({
                defaultMessage:
                    'Создай дуэль, выбери трассу, участок и параметры, и вызови друга на нее, чтобы выяснить отношения по-нашему, а может, просто вместе обновить рекорды на тестировании',
                id: 'Competitions_8aDPOX',
                description: 'соревнования'
            })
        },
        {
            image: competition2Image,
            image2x: competition2Image2x,
            title: intl.formatMessage({
                defaultMessage: 'Велогонки',
                id: 'Competitions_7SOyT+',
                description: 'соревнования'
            }),
            details: intl.formatMessage({
                defaultMessage:
                    'Настоящие соревнования для всех желающих или между клубами, чемпионаты, в том числе с настоящими призами',
                id: 'Competitions_3NKSFV',
                description: 'соревнования'
            })
        },
        {
            image: competition3Image,
            image2x: competition3Image2x,
            title: intl.formatMessage({
                defaultMessage: 'Заезд на треке',
                id: 'Competitions_hv3wQ9',
                description: 'соревнования'
            }),
            details: intl.formatMessage({
                defaultMessage:
                    'Короткие и энергичные гонки на пределе в виде дуэлей, для настоящих буйволов велоспорта',
                id: 'Competitions_gvS9AH',
                description: 'соревнования'
            })
        },
        {
            image: competition4Image,
            image2x: competition4Image2x,
            title: intl.formatMessage({
                defaultMessage: 'Велоквесты',
                id: 'Competitions_H5T3tC',
                description: 'соревнования'
            }),
            details: intl.formatMessage({
                defaultMessage:
                    'Длительные на велостанке еще никогда не были такими интересными, самым умным мы еще и заплатим',
                id: 'Competitions_sOaFh5',
                description: 'соревнования'
            })
        }
    ];

    return (
        <section
            id="competitions"
            className={classes['competitions']}
            aria-labelledby="competitions_title"
        >
            <CustomContainer>
                <Typography
                    variant="h2"
                    component="h2"
                    className={classes['competitions__title']}
                    id="competitions_title"
                >
                    <FormattedMessage
                        defaultMessage="Соревнования"
                        id="Competitions_Setn/5"
                        description="соревнования"
                    />
                </Typography>
                <Typography component='p' variant="h3" mb={8} color="common.white" className={classes['competitions__ribbon']}>
                    <FormattedMessage
                        defaultMessage="В разработке"
                        id="PathsItem_FamFrK"
                        description="карты"
                    />
                </Typography>
                <div className={classes['competitions__list']}>
                    {competitions.map((competition, index) => {
                        return (
                            <div className={classes['competitions__item-wrapper']} key={index}>
                                <Card className={classes['competitions__item']} elevation={12}>
                                    <img
                                        className={classes['competitions__item-image']}
                                        width={481}
                                        height={362}
                                        alt={competition.title}
                                        src={competition.image}
                                        srcSet={`${competition.image} 1x, ${competition.image2x} 2x,`}
                                    />
                                    <CardContent className={classes['competitions__item-content']}>
                                        <Typography
                                            className={classes['competitions__item-title']}
                                            textAlign={'center'}
                                            variant="h3"
                                            component="h3"
                                            color="primary.main"
                                        >
                                            {competition.title}
                                        </Typography>
                                    </CardContent>
                                </Card>
                                <div className={classes['competitions__item-details']}>
                                    {competition.details}
                                </div>
                            </div>
                        );
                    })}
                </div>
                <Typography className={classes['competitions__message']} variant="h2">
                    <FormattedMessage
                        defaultMessage="Бросай вызов своим друзьям, присоединяйся к их тренировке и опережай их в рейтинге!"
                        id="Competitions_0YMXnI"
                        description="соревнования"
                    />
                </Typography>
            </CustomContainer>
        </section>
    );
};

export default Competitions;
