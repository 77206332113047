import { useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../../root-store-context';
import useLocale from '../../../../CustomHooks/useLocale';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import LogoutIcon from '@mui/icons-material/Logout';
import { NAVIGATION_ROUTES } from '../../../../const';
import classes from './CabinetLogOut.module.css';

type CabinetLogOutProps = {
    className?: string;
};

function CabinetLogOut({ className = '' }: CabinetLogOutProps) {
    const intl = useIntl();
    const { user } = useStores();
    const navigate = useNavigate();
    const { localeUrlPrefix } = useLocale();

    const logout = () => {
        navigate(`${localeUrlPrefix}${NAVIGATION_ROUTES.logout}`);
    };
    return (
        <div className={`${classes['cabinet-logout']} ${className}`}>
            <span className={classes['cabinet-logout__user']}>{user.data?.email}</span>
            <IconButton
                onClick={logout}
                aria-label={intl.formatMessage({
                    defaultMessage: 'Выйти',
                    id: 'Cabinet_logoutAttr',
                    description: 'форма входа'
                })}
                className={classes['cabinet-logout__button']}
            >
                <LogoutIcon className={classes['cabinet-logout__icon']} fontSize="medium" />
            </IconButton>
        </div>
    );
}

export default observer(CabinetLogOut);
