import { FormattedMessage } from 'react-intl';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import classes from './Equipment.module.css';

function Equipment() {
    return (
        <Paper className={classes['equipment']} elevation={12}>
            <Typography variant="h3" color="primary">
                <FormattedMessage
                    defaultMessage="Необходимое оборудование"
                    id="downloadAppReqTitle"
                    description="скачать"
                />
            </Typography>
            <dl className={classes['equipment__list']}>
                <div>
                    <dt className={classes['equipment__list-title']}>
                        <Typography variant="h4" component="p">
                            <FormattedMessage
                                defaultMessage="Обязательное"
                                id="Help_nXWQ4o"
                                description="необходимое оборудование"
                            />
                        </Typography>
                    </dt>
                    <dd className={classes['equipment__list-item']}>
                        <FormattedMessage
                            defaultMessage="Станок и/или измеритель мощности c ANT и/или BLE 4.0"
                            id="Help_NwLx8t"
                            description="необходимое оборудование"
                        />
                    </dd>
                    <dd className={classes['equipment__list-item']}>
                        <FormattedMessage
                            defaultMessage="или датчик скорости и совместимый станок"
                            id="Help_NSALx9t"
                            description="необходимое оборудование"
                        />
                    </dd>
                    <dd className={classes['equipment__list-item']}>
                        <FormattedMessage
                            defaultMessage="Любой велосипед"
                            id="Help_i172iJ"
                            description="необходимое оборудование"
                        />
                    </dd>
                </div>
                <div>
                    <dt className={classes['equipment__list-title']}>
                        <Typography variant="h4" component="p">
                            <FormattedMessage
                                defaultMessage="Дополнительное"
                                id="Help_DYkuPs"
                                description="необходимое оборудование"
                            />
                        </Typography>
                    </dt>
                    <dd className={classes['equipment__list-item']}>
                        <FormattedMessage
                            defaultMessage="Переходник ANT USB-Stick для подключения по протоколу ANT"
                            id="Help_IIOouX"
                            description="необходимое оборудование"
                        />
                    </dd>
                    <dd className={classes['equipment__list-item']}>
                        <FormattedMessage
                            defaultMessage="ANT/BLE пульсометр"
                            id="Help_vD61bR"
                            description="необходимое оборудование"
                        />
                    </dd>
                    <dd className={classes['equipment__list-item']}>
                        <FormattedMessage
                            defaultMessage="Датчик каденса"
                            id="Help_vD634bR"
                            description="необходимое оборудование"
                        />
                    </dd>
                </div>
            </dl>
        </Paper>
    );
}

export default Equipment;
