import AppLayout from '../../layouts/AppLayout/AppLayout';
import MainHeader from '../../MainHeader/MainHeader';
import RecoveryPassword from '../../Auth/RecoveryPassword/RecoveryPassword';
import MainFooter from '../../MainFooter/MainFooter';

function RecoveryPasswordPage() {
    return (
        <AppLayout>
            <MainHeader variant="cabinet" />
            <main>
                <RecoveryPassword />
            </main>
            <MainFooter />
        </AppLayout>
    );
}

export default RecoveryPasswordPage;
