import { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { LinearProgress, Slide, SlideProps, Typography } from '@mui/material';

function SlideTransition(props: SlideProps) {
    return <Slide {...props} direction="up" />;
}
type Severity = 'info' | 'error' | 'warning' | 'success';

type AlertContextType = (message: string, severity?: Severity) => void;

const AlertContext = createContext<AlertContextType | undefined>(undefined);

export const useAlertContext = () => {
    const context = useContext(AlertContext);
    if (!context) {
        throw new Error('useAlertContext must be used within a AlertProvider');
    }
    return context;
};

type AlertProviderProps = {
    children: ReactNode;
};

export const AlertProvider: React.FC<AlertProviderProps> = ({ children }) => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState<Severity>('info');
    const [progress, setProgress] = useState(0);
    const [shouldUpdateProgress, setShouldUpdateProgress] = useState(false);

    const showAlert: AlertContextType = (message, severity = 'info') => {
        setMessage(message);
        setSeverity(severity);
        setOpen(true);
        setShouldUpdateProgress(true); // Включить обновление прогресса
    };

    const hideAlert = () => {
        setOpen(false);
        setShouldUpdateProgress(false); // Выключить обновление прогресса
        setProgress(0); // Сбросить прогресс при закрытии
    };

    useEffect(() => {
        let timer: NodeJS.Timeout | null = null;
        const totalTimeInSeconds = 6;
        const totalSteps = 100;

        if (shouldUpdateProgress && progress < 100) {
            const stepsPerSecond = totalSteps / totalTimeInSeconds;
            const interval = 600 / stepsPerSecond;

            timer = setInterval(() => {
                setProgress((prevProgress) => {
                    const nextProgress = prevProgress + 100 / totalSteps;
                    return nextProgress > 100 ? 100 : nextProgress;
                });
            }, interval);
        }

        return () => {
            if (timer) clearInterval(timer);
        };
    }, [progress, shouldUpdateProgress]);

    return (
        <AlertContext.Provider value={showAlert}>
            {children}
            <Snackbar
                open={open}
                autoHideDuration={severity !== 'error' ? 6000 : null}
                onClose={hideAlert}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                key={message}
                TransitionComponent={SlideTransition}
            >
                <Alert
                    onClose={hideAlert}
                    severity={severity}
                    variant="filled"
                    sx={{ width: '100%', alignItems: 'center' }}
                >
                    <Typography variant="subtitle1" component="p">
                        {message}
                    </Typography>

                    {severity !== 'error' && (
                        <LinearProgress variant="determinate" value={progress} />
                    )}
                </Alert>
            </Snackbar>
        </AlertContext.Provider>
    );
};
