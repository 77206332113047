import { FormattedMessage } from 'react-intl';
import CustomContainer from '../CustomContainer/CustomContainer';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import classes from './HowToStart.module.css';
import { NAVIGATION_ROUTES } from '../../../const';

const HowToStart = () => {
    return (
        <section
            aria-labelledby="howToStart-title"
            id="howToStart"
            className={classes['how-to-start']}
        >
            <CustomContainer>
                <Typography
                    id="howToStart-title"
                    variant="h2"
                    component="h2"
                    className={classes['how-to-start__title']}
                >
                    <FormattedMessage
                        defaultMessage="Как начать"
                        id="HowStart_k2+u7w"
                        description="как начать"
                    />
                </Typography>
                <Typography
                    variant="subtitle1"
                    paragraph
                    className={classes['how-to-start__subtitle']}
                >
                    <FormattedMessage
                        defaultMessage="Если последуете нашей инструкции, уже через 10 минут начнется ваша первая тренировка с WattAttack"
                        id="HowStart_ppzgUG"
                        description="как начать"
                    />
                </Typography>
                <div className={classes['how-to-start__steps']}>
                    <div
                        className={`${classes['how-to-start__step']} ${classes['how-to-start__step--right']}`}
                    >
                        <Typography
                            className={`${classes['how-to-start__step-title']}`}
                            color="primary"
                            variant="h2"
                            component="h3"
                        >
                            <FormattedMessage
                                id="HowStart_iG42Gd"
                                description="как начать"
                                defaultMessage="Шаг {step}"
                                values={{ step: 1 }}
                            />
                        </Typography>
                        <p className={`${classes['how-to-start__step-text']}`}>
                            <FormattedMessage
                                id="HowStart_hLHvVc"
                                description="как начать"
                                defaultMessage="Давайте познакомимся! Пройдите простую регистрацию"
                            />
                        </p>
                        <Button
                            className={`${classes['how-to-start__step-button']}`}
                            size="large"
                            variant="contained"
                            href={NAVIGATION_ROUTES.registration}
                        >
                            <FormattedMessage
                                id="HowStart_+Piqsg"
                                description="как начать, кнопка"
                                defaultMessage="Регистрация"
                            />
                        </Button>
                    </div>
                    <div
                        className={`${classes['how-to-start__step']} ${classes['how-to-start__step--both']}`}
                    >
                        <Typography
                            className={`${classes['how-to-start__step-title']}`}
                            color="primary"
                            variant="h2"
                            component="h3"
                        >
                            <FormattedMessage
                                id="HowStart_iG42Gd"
                                defaultMessage="Шаг {step}"
                                description="как начать"
                                values={{ step: 2 }}
                            />
                        </Typography>
                        <p className={`${classes['how-to-start__step-text']}`}>
                            <FormattedMessage
                                id="HowStart_d30tz7"
                                defaultMessage="Скачайте приложение"
                                description="как начать"
                            />
                        </p>
                        <Button
                            className={`${classes['how-to-start__step-button']}`}
                            size="large"
                            variant="contained"
                            href={NAVIGATION_ROUTES.download}
                        >
                            <FormattedMessage
                                id="HowStart_pvN+/g"
                                defaultMessage="Скачать"
                                description="как начать, кнопка"
                            />
                        </Button>
                    </div>
                    <div
                        className={`${classes['how-to-start__step']} ${classes['how-to-start__step--left']}`}
                    >
                        <Typography
                            className={`${classes['how-to-start__step-title']}`}
                            color="primary"
                            variant="h2"
                            component="h3"
                        >
                            <FormattedMessage
                                id="HowStart_iG42Gd"
                                defaultMessage="Шаг {step}"
                                description="как начать"
                                values={{ step: 3 }}
                            />
                        </Typography>
                        <p className={`${classes['how-to-start__step-text']}`}>
                            <FormattedMessage
                                id="HowStart_cGRYZ5"
                                defaultMessage="Введите логин и пароль и начинайте пользоваться приложением"
                                description="как начать"
                            />
                        </p>
                        <Button
                            href={NAVIGATION_ROUTES.login}
                            size="large"
                            variant="contained"
                            className={`${classes['how-to-start__step-button']}`}
                        >
                            <FormattedMessage
                                id="HowStart_Z6+uDo"
                                defaultMessage="Войти"
                                description="как начать, кнопка"
                            />
                        </Button>
                    </div>
                </div>
            </CustomContainer>
        </section>
    );
};

export default HowToStart;
