import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import useLocale from '../../../CustomHooks/useLocale';
import { useTheme } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import useMediaQuery from '@mui/material/useMediaQuery';
import Select, { SelectChangeEvent, selectClasses } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import LanguageIcon from '@mui/icons-material/Language';
import InputLabel from '@mui/material/InputLabel';
import classes from './LocaleSwitcher.module.css';
import { SUPPORTED_LOCALES } from '../../../const';

const messages = defineMessages({
    ru: {
        id: 'index_5sXcOe',
        description: 'переключатель локали',
        defaultMessage: 'Русский'
    },
    en: {
        id: 'index_egllav',
        description: 'переключатель локали',
        defaultMessage: 'English'
    },
    ru_short: {
        id: 'index_YaWCtX',
        description: 'переключатель локали',
        defaultMessage: 'РУ'
    },
    en_short: {
        id: 'index_ECNCax',
        description: 'переключатель локали',
        defaultMessage: 'EN'
    }
});

type LocaleSwitcherProps = {
    className?: string;
    isScrolled?: boolean;
};

function LocaleSwitcher({ className, isScrolled = false }: LocaleSwitcherProps) {
    const { formatMessage: t } = useIntl();
    const { buildUrlInLocale } = useLocale();
    const navigate = useNavigate();
    const theme = useTheme();
    const color = isScrolled ? theme.palette.secondary.main : theme.palette.light.main;
    const { locale } = useLocale();

    const initialLocale = locale === 'ru' ? 'ru' : 'en';

    const [currentLocale, setCurrentLocale] = useState<string>(initialLocale);

    const handleChange = (event: SelectChangeEvent<string>) => {
        const locale = event.target.value as string;
        setCurrentLocale(locale);
        navigate(buildUrlInLocale(locale));
    };

    return (
        <div className={`${className} ${classes['locale-switcher']}`}>
            <LanguageIcon htmlColor={color} />
            <InputLabel sx={visuallyHidden} id="local-switcher-label" htmlFor="locale-switcher">
                <FormattedMessage
                    id="LocaleSwitcher_k2+u7"
                    defaultMessage="Выбор языка"
                    description="переключатель локали"
                />
            </InputLabel>
            <Select
                labelId="local-switcher-label"
                inputProps={{
                    id: 'locale-switcher'
                }}
                autoWidth
                value={currentLocale}
                variant="standard"
                sx={{
                    [`& .${selectClasses.standard}`]: {
                        color: `${color}`,
                        '&:hover': {
                            color: `${theme.palette.primary.main}`
                        }
                    },
                    [`& .${selectClasses.icon}`]: {
                        color: `${color}`,
                        transform: 'translateY(2px)'
                    },
                    '&.MuiInput-root': {
                        '&:before': {
                            bottom: '2px',
                            borderBottom: `2px solid transparent`
                        },
                        '&:hover:before': {
                            borderBottomColor: theme.palette.primary.main
                        }
                    }
                }}
                onChange={handleChange}
                className={`${classes['locale-switcher__select']}`}
            >
                {SUPPORTED_LOCALES.map((locale, i) => {
                    const key = locale === 'en' ? 'en' : 'ru';
                    const shortKey = locale === 'en' ? 'en_short' : 'ru_short';

                    return (
                        <MenuItem
                            className={`${classes['locale-switcher__item']}`}
                            value={locale}
                            key={i}
                        >
                            {useMediaQuery(`(max-width: ${theme.breakpoints.values.md - 1}px)`)
                                ? t({ ...messages[shortKey] })
                                : t({ ...messages[key] })}
                        </MenuItem>
                    );
                })}
            </Select>
        </div>
    );
}

export default LocaleSwitcher;
