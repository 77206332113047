import { FormattedMessage } from 'react-intl';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import DownloadIcon from '@mui/icons-material/Download';
import { APP_DOWNLOAD_LINK_WIN, CURRENT_APP_VERSION } from '../../../../const';
import classes from './DownloadOptions.module.css';

function DownloadWin() {
    return (
        <div className={classes['download-options']}>
            <div>
                <dl className={classes['download-options__requirements']}>
                    <div>
                        <dt>
                            <Typography
                                variant="h5"
                                component="p"
                                className={classes['download-options__subtitle']}
                            >
                                <FormattedMessage
                                    defaultMessage="Минимальные"
                                    id="downloadAppRequirementsMin"
                                    description="скачать"
                                />
                            </Typography>
                        </dt>
                        <dd className={classes['download-options__requirement']}>
                            <FormattedMessage
                                defaultMessage="64-битная Windows 10"
                                id="downloadAppRequirementsMinWin"
                                description="скачать"
                            />
                        </dd>
                        <dd className={classes['download-options__requirement']}>
                            <FormattedMessage
                                defaultMessage="Процессор Intel Core i5-2500 или AMD Ryzen 3 1200"
                                id="downloadAppRequirementsMinProc"
                                description="скачать"
                            />
                        </dd>
                        <dd className={classes['download-options__requirement']}>
                            <FormattedMessage
                                defaultMessage="8 Гб оперативной памяти"
                                id="downloadAppRequirementsMinMem"
                                description="скачать"
                            />
                        </dd>
                        <dd className={classes['download-options__requirement']}>
                            <FormattedMessage
                                defaultMessage="DirectX 11 и 10 Гб свободного места на жестком диске"
                                id="downloadAppRequirementsMinDrX"
                                description="скачать"
                            />
                        </dd>
                        <dd className={classes['download-options__requirement']}>
                            <FormattedMessage
                                defaultMessage="Видеокарта Intel UHD 620"
                                id="downloadAppRequirementsMinVid"
                                description="скачать"
                            />
                        </dd>
                        <dd className={classes['download-options__requirement']}>
                            <FormattedMessage
                                defaultMessage="Последние версии драйверов для видеокарты"
                                id="downloadAppRequirementsMinDrv"
                                description="скачать"
                            />
                        </dd>
                    </div>
                    <div>
                        <dt>
                            <Typography
                                variant="h5"
                                component="p"
                                className={classes['download-options__subtitle']}
                            >
                                <FormattedMessage
                                    defaultMessage="Рекомендуемые"
                                    id="downloadAppRequirementsRec"
                                    description="скачать"
                                />
                            </Typography>
                        </dt>
                        <dd className={classes['download-options__requirement']}>
                            <FormattedMessage
                                defaultMessage="64-битная Windows 11"
                                id="downloadAppRequirementsRecWin"
                                description="скачать"
                            />
                        </dd>
                        <dd className={classes['download-options__requirement']}>
                            <FormattedMessage
                                defaultMessage="Процессор Intel Core i5-7600К или AMD Ryzen 5 1600"
                                id="downloadAppRequirementsRecProc"
                                description="скачать"
                            />
                        </dd>
                        <dd className={classes['download-options__requirement']}>
                            <FormattedMessage
                                defaultMessage="16 Гб оперативной памяти"
                                id="downloadAppRequirementsRecMem"
                                description="скачать"
                            />
                        </dd>
                        <dd className={classes['download-options__requirement']}>
                            <FormattedMessage
                                defaultMessage="DirectX 12 и 10 Гб свободного места на SSD"
                                id="downloadAppRequirementsRecDrX"
                                description="скачать"
                            />
                        </dd>
                        <dd className={classes['download-options__requirement']}>
                            <FormattedMessage
                                defaultMessage="Видеокарта Nvidia GTX 1060 или Radeon RX 570"
                                id="downloadAppRequirementsRecVid"
                                description="скачать"
                            />
                        </dd>
                    </div>
                </dl>
            </div>
            <div className={classes['download-options__details']}>
                <Link
                    className={classes['download-options__link']}
                    href={APP_DOWNLOAD_LINK_WIN}
                    variant="h2"
                    color="info.main"
                    download
                >
                    <FormattedMessage
                        defaultMessage="Скачать"
                        id="downloadAppLink"
                        description="скачать"
                    />{' '}
                    <DownloadIcon className={classes['download-options__icon']} />
                </Link>
                <Typography variant="h4" component="p">
                    {CURRENT_APP_VERSION.windows.size}{' '}
                    <FormattedMessage
                        defaultMessage="Мб"
                        id="downloadAppMb"
                        description="скачать"
                    />
                    ,{' '}
                    <FormattedMessage
                        defaultMessage="версия"
                        id="downloadAppVersion"
                        description="скачать"
                    />{' '}
                    {CURRENT_APP_VERSION.windows.version}
                </Typography>
            </div>
        </div>
    );
}

export default DownloadWin;
