import { useState } from 'react';
import { useAlertContext } from '../../Context/AlertContext';
import { useStores } from '../../../../root-store-context';
import { SubscriptionType } from '../../../../services/SubscriptionService';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import classes from './CabinetSubscriptionPlans.module.css';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import validatePromoCode from '../../../../services/promoCodeService';

type CabinetSubscriptionPlansProps = {
    isDonator?: boolean | null;
    isPromoActive?: boolean;
}

type PromoCode = {
    id: string;
    code: string | null;
    expiresAt: string | null;
    discountType: string | null;
    discountValue: number | null;
    usageLimit: number | null;
    description: string | null;
    usagePeriods?: number | null;
};

function CabinetSubscriptionPlans({ isDonator }: CabinetSubscriptionPlansProps) {
    const { subscription } = useStores();
    const showAlert = useAlertContext();
    const [promoCode, setPromoCode] = useState('');
    const [showPromoCodeInput, setShowPromoCodeInput] = useState(false);
    const [promoCodeValid, setPromoCodeValid] = useState(false);
    const [appliedPromoCode, setAppliedPromoCode] = useState<PromoCode | null>(null);
    const [isPromoYear, setIsPromoYear] = useState(false);

    const subscriptionPlan = 'MTH';
    const basePrice = 1000;
    const type = 'Месяц';

    // Высчитываем новую цену, если есть скидка
    const calculatePriceWithDiscount = (promoCode: PromoCode | null) => {
        let finalPrice = basePrice;

        if (promoCode && promoCode.discountType && promoCode.discountValue) {
            if (promoCode.discountType === 'percentage') {
                // Применение процентной скидки
                finalPrice -= (finalPrice * promoCode.discountValue) / 100;
            } else if (promoCode.discountType === 'fixed') {
                // Применение фиксированной скидки
                finalPrice -= promoCode.discountValue;
            }
        }

        // Цена не может быть меньше одного рубля и округляется
        return Math.max(1, Math.round(finalPrice));
    };

    const finalPrice = calculatePriceWithDiscount(appliedPromoCode);

    const handleSubscribe = async (type: SubscriptionType) => {

        try {
            await subscription.create({
                type: type,
                code: promoCode
            });

            const paymentUrl = subscription.data?.paymentUrl;

            if (paymentUrl) {
                window.location.href = paymentUrl;
            } else {
                showAlert('Произошла ошибка', 'error');
            }
        } catch (error) {
            console.error('Ошибка при создании подписки:', error);
            showAlert('Произошла ошибка', 'error');
        }
    };

    const handlePromoSubscribe = async () => {

        try {
            await subscription.createPromo({
                type: 'YEAR',
                code: promoCode
            });
            showAlert('Подписка обновлена', 'success');

        } catch (error) {
            console.error('Ошибка при создании подписки:', error);
            showAlert('Произошла ошибка', 'error');
        }
    };

    const handleUpdateSubscription = async (type: SubscriptionType) => {

        try {
            await subscription.update({
                type: type,
                code: promoCode

            });

            const paymentUrl = subscription.data?.paymentUrl;

            if (paymentUrl) {
                window.location.href = paymentUrl;
            } else {
                showAlert('Произошла ошибка', 'error');
            }
        } catch (error) {
            console.error('Ошибка при обновлении подписки:', error);
            showAlert('Произошла ошибка', 'error');
        }
    };

    const handlePromoCodeCheck = async () => {
        try {
            const trimmedPromoCode = promoCode.trim();
            const response = await validatePromoCode({ code: trimmedPromoCode });
            if (response.valid && response.promoCode) {
                setPromoCodeValid(true);
                setAppliedPromoCode(response.promoCode);
                setShowPromoCodeInput(false);
                if (response.promoCode?.applicablePlans && response.promoCode?.applicablePlans.includes('YEAR')) {
                    setIsPromoYear(true)
                }
            } else {
                setPromoCodeValid(false);
                showAlert('Промокод не действителен', 'error');
            }
        } catch (error) {
            console.error('Ошибка при проверке промокода:', error);
            showAlert('Ошибка при проверке промокода', 'error');
        }
    };

    const handleClosePromoCodeInput = () => {
        setShowPromoCodeInput(false);
        setPromoCode('');
        setPromoCodeValid(false);
        setAppliedPromoCode(null);
    };

    return (
        <Box className={classes['cabinet-subscription-plans__container']}>
            <Typography variant='h3'>Тарифные планы</Typography>
            <Box className={classes['cabinet-subscription-plans__list']}>
                <Card elevation={4} className={classes['cabinet-subscription-plans__item']}>
                    <Typography variant="h3">{type}</Typography>
                    {/* Показываем старую цену зачёркнутой, если применён промокод */}
                    {!isPromoYear && (
                        <div>
                            {appliedPromoCode ? (
                                <Typography variant="subtitle2">
                                    <del>{basePrice} ₽</del> <ins>{finalPrice} ₽</ins>
                                </Typography>
                            ) : (
                                <Typography variant="subtitle2">{basePrice} ₽</Typography>
                            )}
                        </div>
                    )}
                    {isPromoYear && (
                        <Typography variant="subtitle2">
                            <strong>0 ₽</strong>
                        </Typography>
                    )}

                    {!isPromoYear && (
                        <Typography sx={{ textAlign: 'center' }}>
                            Платёж будет автоматически списываться каждый месяц.
                        </Typography>
                    )}
                    {isPromoYear && (
                        <Typography sx={{ textAlign: 'center' }}>
                            Подписка без автоплатежа.
                        </Typography>
                    )}

                    {isDonator && !isPromoYear && (
                        <Button onClick={() => handleUpdateSubscription(subscriptionPlan)} sx={{ marginBlockStart: 'auto' }} variant="contained">
                            Обновить подписку за 1 рубль
                        </Button>
                    )}
                    {!isDonator && !isPromoYear && (
                        <Button onClick={() => handleSubscribe(subscriptionPlan)} sx={{ marginBlockStart: 'auto' }} variant="contained">
                            Подписаться
                        </Button>
                    )}
                    {isPromoYear && (
                        <Button onClick={() => handlePromoSubscribe()} sx={{ marginBlockStart: 'auto' }} variant="contained">
                            Получить годовую подписку
                        </Button>
                    )}
                    {!showPromoCodeInput && !isPromoYear && (
                        <Button
                            onClick={() => setShowPromoCodeInput(true)}
                            sx={{ marginTop: '1rem' }}
                            variant="outlined"
                        >
                            Ввести промокод
                        </Button>
                    )}
                    {showPromoCodeInput && (
                        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '1rem' }}>
                            <TextField
                                label="Промокод"
                                variant="outlined"
                                fullWidth
                                value={promoCode}
                                onChange={(e) => setPromoCode(e.target.value)}
                            />
                            <IconButton onClick={handlePromoCodeCheck} color="primary" sx={{ marginLeft: '0.5rem' }}>
                                <CheckIcon />
                            </IconButton>
                            <IconButton onClick={handleClosePromoCodeInput} color="secondary" sx={{ marginLeft: '0.5rem' }}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    )}
                    {promoCodeValid && appliedPromoCode && (
                        <Typography sx={{ textAlign: 'center', marginTop: '0.5rem', color: 'green' }}>
                            Промокод применён: {appliedPromoCode.code}
                            {appliedPromoCode.usagePeriods && !isPromoYear && (
                                <Typography variant="body2">
                                    Количество месяцев со скидкой по промо-коду: {appliedPromoCode.usagePeriods}.
                                </Typography>
                            )}

                            {appliedPromoCode.usagePeriods && isPromoYear && (
                                <Typography variant="body2">
                                    1 год подписки бесплатно.
                                </Typography>
                            )}
                        </Typography>
                    )}
                </Card>
            </Box>
        </Box>
    );
}

export default CabinetSubscriptionPlans;
