export interface CountryType {
    name: string;
    name_ru: string;
    code: string;
}

const countries: readonly CountryType[] = [
    { name: 'Afghanistan', name_ru: 'Афганистан', code: 'AF' },
    { name: 'land Islands', name_ru: 'Аландские острова', code: 'AX' },
    { name: 'Albania', name_ru: 'Албания', code: 'AL' },
    { name: 'Algeria', name_ru: 'Алжир', code: 'DZ' },
    { name: 'American Samoa', name_ru: 'Американское Самоа', code: 'AS' },
    { name: 'AndorrA', name_ru: 'Андорра', code: 'AD' },
    { name: 'Angola', name_ru: 'Ангола', code: 'AO' },
    { name: 'Anguilla', name_ru: 'Ангилья', code: 'AI' },
    { name: 'Antarctica', name_ru: 'Антарктика', code: 'AQ' },
    { name: 'Antigua and Barbuda', name_ru: 'Антигуа и Барбуда', code: 'AG' },
    { name: 'Argentina', name_ru: 'Аргентина', code: 'AR' },
    { name: 'Armenia', name_ru: 'Армения', code: 'AM' },
    { name: 'Aruba', name_ru: 'Аруба', code: 'AW' },
    { name: 'Australia', name_ru: 'Австралия', code: 'AU' },
    { name: 'Austria', name_ru: 'Австрия', code: 'AT' },
    { name: 'Azerbaijan', name_ru: 'Азербайджан', code: 'AZ' },
    { name: 'Bahamas', name_ru: 'Багамские острова', code: 'BS' },
    { name: 'Bahrain', name_ru: 'Бахрейн', code: 'BH' },
    { name: 'Bangladesh', name_ru: 'Бангладеш', code: 'BD' },
    { name: 'Barbados', name_ru: 'Барбадос', code: 'BB' },
    { name: 'Belarus', name_ru: 'Беларусь', code: 'BY' },
    { name: 'Belgium', name_ru: 'Бельгия', code: 'BE' },
    { name: 'Belize', name_ru: 'Белиз', code: 'BZ' },
    { name: 'Benin', name_ru: 'Бенин', code: 'BJ' },
    { name: 'Bermuda', name_ru: 'Бермудские острова', code: 'BM' },
    { name: 'Bhutan', name_ru: 'Бутан', code: 'BT' },
    { name: 'Bolivia', name_ru: 'Боливия', code: 'BO' },
    { name: 'Bosnia and Herzegovina', name_ru: 'Босния и Герцеговина', code: 'BA' },
    { name: 'Botswana', name_ru: 'Ботсвана', code: 'BW' },
    { name: 'Bouvet Island', name_ru: 'Остров Буве', code: 'BV' },
    { name: 'Brazil', name_ru: 'Бразилия', code: 'BR' },
    {
        name: 'British Indian Ocean Territory',
        name_ru: 'Британская территория в Индийском океане',
        code: 'IO'
    },
    { name: 'Brunei Darussalam', name_ru: 'Бруней', code: 'BN' },
    { name: 'Bulgaria', name_ru: 'Болгария', code: 'BG' },
    { name: 'Burkina Faso', name_ru: 'Буркина-Фасо', code: 'BF' },
    { name: 'Burundi', name_ru: 'Бурунди', code: 'BI' },
    { name: 'Cambodia', name_ru: 'Камбоджа', code: 'KH' },
    { name: 'Cameroon', name_ru: 'Камерун', code: 'CM' },
    { name: 'Canada', name_ru: 'Канада', code: 'CA' },
    { name: 'Cape Verde', name_ru: 'Кабо-Верде', code: 'CV' },
    { name: 'Cayman Islands', name_ru: 'Каймановы острова', code: 'KY' },
    { name: 'Central African Republic', name_ru: 'Центральноафриканская Республика', code: 'CF' },
    { name: 'Chad', name_ru: 'Чад', code: 'TD' },
    { name: 'Chile', name_ru: 'Чили', code: 'CL' },
    { name: 'China', name_ru: 'Китай', code: 'CN' },
    { name: 'Christmas Island', name_ru: 'Остров Рождества', code: 'CX' },
    { name: 'Cocos (Keeling) Islands', name_ru: 'Кокосовые (Килинг) острова', code: 'CC' },
    { name: 'Colombia', name_ru: 'Колумбия', code: 'CO' },
    { name: 'Comoros', name_ru: 'Коморские острова', code: 'KM' },
    { name: 'Congo', name_ru: 'Конго', code: 'CG' },
    {
        name: 'Congo, The Democratic Republic of the',
        name_ru: 'Конго, Демократическая Республика',
        code: 'CD'
    },
    { name: 'Cook Islands', name_ru: 'Острова Кука', code: 'CK' },
    { name: 'Costa Rica', name_ru: 'Коста-Рика', code: 'CR' },
    { name: 'Cote D`Ivoire', name_ru: 'Кот д`Ивуар', code: 'CI' },
    { name: 'Croatia', name_ru: 'Хорватия', code: 'HR' },
    { name: 'Cuba', name_ru: 'Куба', code: 'CU' },
    { name: 'Cyprus', name_ru: 'Кипр', code: 'CY' },
    { name: 'Czech Republic', name_ru: 'Чехия', code: 'CZ' },
    { name: 'Denmark', name_ru: 'Дания', code: 'DK' },
    { name: 'Djibouti', name_ru: 'Джибути', code: 'DJ' },
    { name: 'Dominica', name_ru: 'Доминика', code: 'DM' },
    { name: 'Dominican Republic', name_ru: 'Доминиканская Республика', code: 'DO' },
    { name: 'Ecuador', name_ru: 'Эквадор', code: 'EC' },
    { name: 'Egypt', name_ru: 'Египет', code: 'EG' },
    { name: 'El Salvador', name_ru: 'Сальвадор', code: 'SV' },
    { name: 'Equatorial Guinea', name_ru: 'Экваториальная Гвинея', code: 'GQ' },
    { name: 'Eritrea', name_ru: 'Эритрея', code: 'ER' },
    { name: 'Estonia', name_ru: 'Эстония', code: 'EE' },
    { name: 'Ethiopia', name_ru: 'Эфиопия', code: 'ET' },
    {
        name: 'Falkland Islands (Malvinas)',
        name_ru: 'Фолклендские острова (Мальвинские острова)',
        code: 'FK'
    },
    { name: 'Faroe Islands', name_ru: 'Фарерские острова', code: 'FO' },
    { name: 'Fiji', name_ru: 'Фиджи', code: 'FJ' },
    { name: 'Finland', name_ru: 'Финляндия', code: 'FI' },
    { name: 'France', name_ru: 'Франция', code: 'FR' },
    { name: 'French Guiana', code: 'GF', name_ru: 'Французская Гвиана' },
    { name: 'French Polynesia', code: 'PF', name_ru: 'Французская Полинезия' },
    {
        name: 'French Southern Territories',
        code: 'TF',
        name_ru: 'Французские Южные и Антарктические территории'
    },
    { name: 'Gabon', code: 'GA', name_ru: 'Габон' },
    { name: 'Gambia', code: 'GM', name_ru: 'Гамбия' },
    { name: 'Georgia', code: 'GE', name_ru: 'Грузия' },
    { name: 'Germany', code: 'DE', name_ru: 'Германия' },
    { name: 'Ghana', code: 'GH', name_ru: 'Гана' },
    { name: 'Gibraltar', code: 'GI', name_ru: 'Гибралтар' },
    { name: 'Greece', code: 'GR', name_ru: 'Греция' },
    { name: 'Greenland', code: 'GL', name_ru: 'Гренландия' },
    { name: 'Grenada', code: 'GD', name_ru: 'Гренада' },
    { name: 'Guadeloupe', code: 'GP', name_ru: 'Гваделупа' },
    { name: 'Guam', code: 'GU', name_ru: 'Гуам' },
    { name: 'Guatemala', code: 'GT', name_ru: 'Гватемала' },
    { name: 'Guernsey', code: 'GG', name_ru: 'Гернси' },
    { name: 'Guinea', code: 'GN', name_ru: 'Гвинея' },
    { name: 'Guinea-Bissau', code: 'GW', name_ru: 'Гвинея-Бисау' },
    { name: 'Guyana', code: 'GY', name_ru: 'Гайана' },
    { name: 'Haiti', code: 'HT', name_ru: 'Гаити' },
    {
        name: 'Heard Island and Mcdonald Islands',
        code: 'HM',
        name_ru: 'Остров Херд и острова Макдональд'
    },
    {
        name: 'Holy See (Vatican City State)',
        code: 'VA',
        name_ru: 'Святой Престол (государство-город Ватикан)'
    },
    { name: 'Honduras', code: 'HN', name_ru: 'Гондурас' },
    { name: 'Hong Kong', code: 'HK', name_ru: 'Гонконг' },
    { name: 'Hungary', code: 'HU', name_ru: 'Венгрия' },
    { name: 'Iceland', code: 'IS', name_ru: 'Исландия' },
    { name: 'India', code: 'IN', name_ru: 'Индия' },
    { name: 'Indonesia', code: 'ID', name_ru: 'Индонезия' },
    { name: 'Iran, Islamic Republic Of', code: 'IR', name_ru: 'Иран, Исламская Республика' },
    { name: 'Iraq', code: 'IQ', name_ru: 'Ирак' },
    { name: 'Ireland', code: 'IE', name_ru: 'Ирландия' },
    { name: 'Isle of Man', code: 'IM', name_ru: 'Остров Мэн' },
    { name: 'Israel', code: 'IL', name_ru: 'Израиль' },
    { name: 'Italy', code: 'IT', name_ru: 'Италия' },
    { name: 'Jamaica', code: 'JM', name_ru: 'Ямайка' },
    { name: 'Japan', code: 'JP', name_ru: 'Япония' },
    { name: 'Jersey', code: 'JE', name_ru: 'Джерси' },
    { name: 'Jordan', code: 'JO', name_ru: 'Иордания' },
    { name: 'Kazakhstan', code: 'KZ', name_ru: 'Казахстан' },
    { name: 'Kenya', code: 'KE', name_ru: 'Кения' },
    { name: 'Kiribati', code: 'KI', name_ru: 'Кирибати' },
    {
        name: 'Korea, Democratic People`S Republic of',
        code: 'KP',
        name_ru: 'Корея, Народно-Демократическая Республика'
    },
    { name: 'Korea, Republic of', code: 'KR', name_ru: 'Корея, Республика' },
    { name: 'Kuwait', code: 'KW', name_ru: 'Кувейт' },
    { name: 'Kyrgyzstan', code: 'KG', name_ru: 'Киргизия' },
    {
        name: 'Lao People`S Democratic Republic',
        code: 'LA',
        name_ru: 'Лаос, Народно-Демократическая Республика'
    },
    { name: 'Latvia', code: 'LV', name_ru: 'Латвия' },
    { name: 'Lebanon', code: 'LB', name_ru: 'Ливан' },
    { name: 'Lesotho', code: 'LS', name_ru: 'Лесото' },
    { name: 'Liberia', code: 'LR', name_ru: 'Либерия' },
    { name: 'Libyan Arab Jamahiriya', code: 'LY', name_ru: 'Ливийская Арабская Джамахирия' },
    { name: 'Liechtenstein', code: 'LI', name_ru: 'Лихтенштейн' },
    { name: 'Lithuania', code: 'LT', name_ru: 'Литва' },
    { name: 'Luxembourg', code: 'LU', name_ru: 'Люксембург' },
    { name: 'Macao', code: 'MO', name_ru: 'Макао' },
    {
        name: 'Macedonia, The Former Yugoslav Republic of',
        code: 'MK',
        name_ru: 'Северная Македония, бывшая Югославская Республика'
    },
    { name: 'Madagascar', code: 'MG', name_ru: 'Мадагаскар' },
    { name: 'Malawi', code: 'MW', name_ru: 'Малави' },
    { name: 'Malaysia', code: 'MY', name_ru: 'Малайзия' },
    { name: 'Maldives', code: 'MV', name_ru: 'Мальдивы' },
    { name: 'Mali', code: 'ML', name_ru: 'Мали' },
    { name: 'Malta', code: 'MT', name_ru: 'Мальта' },
    { name: 'Marshall Islands', code: 'MH', name_ru: 'Маршалловы Острова' },
    { name: 'Martinique', code: 'MQ', name_ru: 'Мартиника' },
    { name: 'Mauritania', code: 'MR', name_ru: 'Мавритания' },
    { name: 'Mauritius', code: 'MU', name_ru: 'Маврикий' },
    { name: 'Mayotte', code: 'YT', name_ru: 'Майотта' },
    { name: 'Mexico', code: 'MX', name_ru: 'Мексика' },
    {
        name: 'Micronesia, Federated States of',
        code: 'FM',
        name_ru: 'Федеративные Штаты Микронезии'
    },
    { name: 'Moldova, Republic of', code: 'MD', name_ru: 'Молдова, Республика' },
    { name: 'Monaco', code: 'MC', name_ru: 'Монако' },
    { name: 'Mongolia', code: 'MN', name_ru: 'Монголия' },
    { name: 'Montenegro', code: 'ME', name_ru: 'Черногория' },
    { name: 'Montserrat', code: 'MS', name_ru: 'Монтсеррат' },
    { name: 'Morocco', code: 'MA', name_ru: 'Марокко' },
    { name: 'Mozambique', code: 'MZ', name_ru: 'Мозамбик' },
    { name: 'Myanmar', code: 'MM', name_ru: 'Мьянма' },
    { name: 'Namibia', code: 'NA', name_ru: 'Намибия' },
    { name: 'Nauru', code: 'NR', name_ru: 'Науру' },
    { name: 'Nepal', code: 'NP', name_ru: 'Непал' },
    { name: 'Netherlands', code: 'NL', name_ru: 'Нидерланды' },
    { name: 'Netherlands Antilles', code: 'AN', name_ru: 'Нидерландские Антильские острова' },
    { name: 'New Caledonia', code: 'NC', name_ru: 'Новая Каледония' },
    { name: 'New Zealand', code: 'NZ', name_ru: 'Новая Зеландия' },
    { name: 'Nicaragua', code: 'NI', name_ru: 'Никарагуа' },
    { name: 'Niger', code: 'NE', name_ru: 'Нигер' },
    { name: 'Nigeria', code: 'NG', name_ru: 'Нигерия' },
    { name: 'Niue', code: 'NU', name_ru: 'Ниуэ' },
    { name: 'Norfolk Island', code: 'NF', name_ru: 'Остров Норфолк' },
    { name: 'Northern Mariana Islands', code: 'MP', name_ru: 'Северные Марианские острова' },
    { name: 'Norway', code: 'NO', name_ru: 'Норвегия' },
    { name: 'Oman', code: 'OM', name_ru: 'Оман' },
    { name: 'Pakistan', code: 'PK', name_ru: 'Пакистан' },
    { name: 'Palau', code: 'PW', name_ru: 'Палау' },
    {
        name: 'Palestinian Territory, Occupied',
        code: 'PS',
        name_ru: 'Палестинская территория, оккупированная'
    },
    { name: 'Panama', code: 'PA', name_ru: 'Панама' },
    { name: 'Papua New Guinea', code: 'PG', name_ru: 'Папуа - Новая Гвинея' },
    { name: 'Paraguay', code: 'PY', name_ru: 'Парагвай' },
    { name: 'Peru', code: 'PE', name_ru: 'Перу' },
    { name: 'Philippines', code: 'PH', name_ru: 'Филиппины' },
    { name: 'Pitcairn', code: 'PN', name_ru: 'Питкэрн' },
    { name: 'Poland', code: 'PL', name_ru: 'Польша' },
    { name: 'Portugal', code: 'PT', name_ru: 'Португалия' },
    { name: 'Puerto Rico', code: 'PR', name_ru: 'Пуэрто-Рико' },
    { name: 'Qatar', code: 'QA', name_ru: 'Катар' },
    { name: 'Reunion', code: 'RE', name_ru: 'Реюньон' },
    { name: 'Romania', code: 'RO', name_ru: 'Румыния' },
    { name: 'Russian Federation', code: 'RU', name_ru: 'Российская Федерация' },
    { name: 'RWANDA', code: 'RW', name_ru: 'Руанда' },
    { name: 'Saint Helena', code: 'SH', name_ru: 'Остров Святой Елены' },
    { name: 'Saint Kitts and Nevis', code: 'KN', name_ru: 'Сент-Киттс и Невис' },
    { name: 'Saint Lucia', code: 'LC', name_ru: 'Сент-Люсия' },
    { name: 'Saint Pierre and Miquelon', code: 'PM', name_ru: 'Сен-Пьер и Микелон' },
    { name: 'Saint Vincent and the Grenadines', code: 'VC', name_ru: 'Сент-Винсент и Гренадины' },
    { name: 'Samoa', code: 'WS', name_ru: 'Самоа' },
    { name: 'San Marino', code: 'SM', name_ru: 'Сан-Марино' },
    { name: 'Sao Tome and Principe', code: 'ST', name_ru: 'Сан-Томе и Принсипи' },
    { name: 'Saudi Arabia', code: 'SA', name_ru: 'Саудовская Аравия' },
    { name: 'Senegal', code: 'SN', name_ru: 'Сенегал' },
    { name: 'Serbia', code: 'RS', name_ru: 'Сербия' },
    { name: 'Seychelles', code: 'SC', name_ru: 'Сейшельские острова' },
    { name: 'Sierra Leone', code: 'SL', name_ru: 'Сьерра-Леоне' },
    { name: 'Singapore', code: 'SG', name_ru: 'Сингапур' },
    { name: 'Slovakia', code: 'SK', name_ru: 'Словакия' },
    { name: 'Slovenia', code: 'SI', name_ru: 'Словения' },
    { name: 'Solomon Islands', code: 'SB', name_ru: 'Соломоновы острова' },
    { name: 'Somalia', code: 'SO', name_ru: 'Сомали' },
    { name: 'South Africa', code: 'ZA', name_ru: 'Южная Африка' },
    {
        name: 'South Georgia and the South Sandwich Islands',
        code: 'GS',
        name_ru: 'Южная Георгия и Южные Сандвичевы острова'
    },
    { name: 'Spain', code: 'ES', name_ru: 'Испания' },
    { name: 'Sri Lanka', code: 'LK', name_ru: 'Шри-Ланка' },
    { name: 'Sudan', code: 'SD', name_ru: 'Судан' },
    { name: 'Suriname', code: 'SR', name_ru: 'Суринам' },
    { name: 'Svalbard and Jan Mayen', code: 'SJ', name_ru: 'Шпицберген и Ян-Майен' },
    { name: 'Swaziland', code: 'SZ', name_ru: 'Свазиленд' },
    { name: 'Sweden', code: 'SE', name_ru: 'Швеция' },
    { name: 'Switzerland', code: 'CH', name_ru: 'Швейцария' },
    { name: 'Syrian Arab Republic', code: 'SY', name_ru: 'Сирийская Арабская Республика' },
    { name: 'Taiwan, Province of China', code: 'TW', name_ru: 'Тайвань, Провинция Китая' },
    { name: 'Tajikistan', code: 'TJ', name_ru: 'Таджикистан' },
    {
        name: 'Tanzania, United Republic of',
        code: 'TZ',
        name_ru: 'Объединенная Республика Танзания'
    },
    { name: 'Thailand', code: 'TH', name_ru: 'Таиланд' },
    { name: 'Timor-Leste', code: 'TL', name_ru: 'Восточный Тимор' },
    { name: 'Togo', code: 'TG', name_ru: 'Того' },
    { name: 'Tokelau', code: 'TK', name_ru: 'Токелау' },
    { name: 'Tonga', code: 'TO', name_ru: 'Тонга' },
    { name: 'Trinidad and Tobago', code: 'TT', name_ru: 'Тринидад и Тобаго' },
    { name: 'Tunisia', code: 'TN', name_ru: 'Тунис' },
    { name: 'Turkey', code: 'TR', name_ru: 'Турция' },
    { name: 'Turkmenistan', code: 'TM', name_ru: 'Туркменистан' },
    { name: 'Turks and Caicos Islands', code: 'TC', name_ru: 'Теркс и Кайкос' },
    { name: 'Tuvalu', code: 'TV', name_ru: 'Тувалу' },
    { name: 'Uganda', code: 'UG', name_ru: 'Уганда' },
    { name: 'Ukraine', code: 'UA', name_ru: 'Украина' },
    { name: 'United Arab Emirates', code: 'AE', name_ru: 'Объединенные Арабские Эмираты' },
    { name: 'United Kingdom', code: 'GB', name_ru: 'Великобритания' },
    { name: 'United States', code: 'US', name_ru: 'Соединенные Штаты Америки' },
    {
        name: 'United States Minor Outlying Islands',
        code: 'UM',
        name_ru: 'Внешние малые острова США'
    },
    { name: 'Uruguay', code: 'UY', name_ru: 'Уругвай' },
    { name: 'Uzbekistan', code: 'UZ', name_ru: 'Узбекистан' },
    { name: 'Vanuatu', code: 'VU', name_ru: 'Вануату' },
    { name: 'Venezuela', code: 'VE', name_ru: 'Венесуэла' },
    { name: 'Viet Nam', code: 'VN', name_ru: 'Вьетнам' },
    { name: 'Virgin Islands, British', code: 'VG', name_ru: 'Британские Виргинские острова' },
    { name: 'Virgin Islands, U.S.', code: 'VI', name_ru: 'Американские Виргинские острова' },
    { name: 'Wallis and Futuna', code: 'WF', name_ru: 'Уоллис и Футуна' },
    { name: 'Western Sahara', code: 'EH', name_ru: 'Западная Сахара' },
    { name: 'Yemen', code: 'YE', name_ru: 'Йемен' },
    { name: 'Zambia', code: 'ZM', name_ru: 'Замбия' },
    { name: 'Zimbabwe', code: 'ZW', name_ru: 'Зимбабве' }
];

export default countries;
