import { buildApiUrl } from './api';

export type Connection = {
    appId: string;
    createdAt: string;
};

type ErrorResponse = {
    code: string;
};

type FaultResponse = {
    message: string;
    errors: ErrorResponse[];
};

type SuccessfulGetBody = {
    connections: any; // TODO
};

type SuccessfulOauthConnectBody = {
    authorizeUrl: string;
};

type SuccessfulOauthCallbackBody = {
    connection: any // TODO
};

type SuccessfulOauthUnConnectBody = {
    status: boolean;
};

type BadRequestBody = FaultResponse;

type ConnectionServiceGetResponse = Omit<Response, 'json'> & {
    status: 200;
    json: () => SuccessfulGetBody | PromiseLike<SuccessfulGetBody>;
};

type ConnectionServiceOauthConnectResponse =
    | (Omit<Response, 'json'> & {
          status: 200;
          json: () => SuccessfulOauthConnectBody | PromiseLike<SuccessfulOauthConnectBody>;
      })
    | (Omit<Response, 'json'> & {
          status: 400;
          json: () => BadRequestBody | PromiseLike<BadRequestBody>;
      });

type ConnectionServiceUnConnectResponse =
      | (Omit<Response, 'json'> & {
            status: 200;
            json: () => SuccessfulOauthUnConnectBody | PromiseLike<SuccessfulOauthUnConnectBody>;
        })
      | (Omit<Response, 'json'> & {
            status: 400;
            json: () => BadRequestBody | PromiseLike<BadRequestBody>;
        });

type ConnectionServiceOauthCallbackResponse =
      | (Omit<Response, 'json'> & {
            status: 200;
            json: () => SuccessfulOauthCallbackBody | PromiseLike<SuccessfulOauthCallbackBody>;
        })
      | (Omit<Response, 'json'> & {
            status: 400;
            json: () => BadRequestBody | PromiseLike<BadRequestBody>;
        });

type ConnectionServiceOauthConnectRequest = {
    appId: string;
    redirectUrl: string;
};

type ConnectionServiceUnConnectRequest = {
    appId: string;
};

type ConnectionServiceOauthCallbackRequest = {
    token: string;
    code: string;
    scope: string;
};

class ConnectionService {
    static async get() {
        const response = (await fetch(buildApiUrl('/connections'), {
            method: 'GET'
        })) as ConnectionServiceGetResponse;

        if (response.status === 200) {
            return response.json();
        }

        throw Error('Unhandled response code');
    }

    static async oAuthConnect(data: ConnectionServiceOauthConnectRequest) {
        const response = (await fetch(buildApiUrl('/connection/oauth/connect'), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })) as ConnectionServiceOauthConnectResponse;

        if (response.status === 200) {
            return response.json();
        }

        throw Error('Unhandled response code');
    }

    static async unConnect(data: ConnectionServiceUnConnectRequest) {
        const response = (await fetch(buildApiUrl('/connection/unconnect'), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })) as ConnectionServiceUnConnectResponse;

        if (response.status === 200) {
            return response.json();
        }

        throw Error('Unhandled response code');
    }

    static async oAuthCallback(data: ConnectionServiceOauthCallbackRequest) {
        const response = (await fetch(buildApiUrl('/connection/oauth/callback'), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })) as ConnectionServiceOauthCallbackResponse;

        if (response.status === 200) {
            return response.json();
        }

        throw Error('Unhandled response code');
    }
}

export default ConnectionService;
