import { buildApiUrl } from './api';
import { User } from './UserService';

type ErrorResponse = {
    code: string;
};

type FaultResponse = {
    message: string;
    errors: ErrorResponse[];
};

type SuccessfulLoginBody = {
    user: User;
};

type BadRequestBody = FaultResponse;
type NotFoundBody = FaultResponse;

type AuthServiceLoginResponse =
    | (Omit<Response, 'json'> & {
          status: 200;
          json: () => SuccessfulLoginBody | PromiseLike<SuccessfulLoginBody>;
      })
    | (Omit<Response, 'json'> & {
          status: 404;
          json: () => NotFoundBody | PromiseLike<NotFoundBody>;
      })
    | (Omit<Response, 'json'> & {
          status: 400;
          json: () => BadRequestBody | PromiseLike<BadRequestBody>;
      });

type SuccessfulCheckBody = {
    user?: User;
};

type AuthServiceCheckResponse = Omit<Response, 'json'> & {
    status: 200;
    json: () => SuccessfulCheckBody | PromiseLike<SuccessfulCheckBody>;
};

type SuccessfulLogoutBody = {};

type AuthServiceLogoutResponse = Omit<Response, 'json'> & {
    status: 200;
    json: () => SuccessfulLogoutBody | PromiseLike<SuccessfulLogoutBody>;
};

class AuthService {
    static async login(email: string, password: string) {
        const response = (await fetch(buildApiUrl('/auth/login'), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email,
                password
            })
        })) as AuthServiceLoginResponse;

        if (response.status === 200 || response.status === 400 || response.status === 404) {
            return response.json();
        }

        throw Error('Unhandled response code');
    }

    static async logout() {
        const response = (await fetch(buildApiUrl('/auth/logout'), {
            method: 'POST'
        })) as AuthServiceLogoutResponse;

        if (response.status === 200) {
            return response.json();
        }

        throw Error('Unhandled response code');
    }

    static async check() {
        const response = (await fetch(buildApiUrl('/auth/check'), {
            method: 'GET'
        })) as AuthServiceCheckResponse;

        if (response.status === 200) {
            return response.json();
        }

        throw Error('Unhandled response code');
    }
}

export default AuthService;
