import { useSearchParams } from 'react-router-dom';

import ChangePasswordForm from './ChangePasswordForm/ChangePasswordForm';
import RecoverPasswordForm from './RecoveryPasswordForm/RecoveryPasswordForm';

const RecoveryPassword = () => {
    const [urlSearchParams] = useSearchParams();
    const token = urlSearchParams.get('token');

    if (token) {
        return <ChangePasswordForm token={token} />;
    } else {
        return <RecoverPasswordForm />;
    }
};

export default RecoveryPassword;
