import Fab from '@mui/material/Fab';
import TelegramIcon from '@mui/icons-material/Telegram';
import classes from './FloatingTelegramButton.module.css';

function FloatingTelegramButton() {
    return (
        <Fab
            color="primary"
            aria-label="Telegram"
            href="https://t.me/wattattack"
            className={classes['floating-telegram-button']}
        >
            <TelegramIcon fontSize="large" />
        </Fab>
    );
}

export default FloatingTelegramButton;
