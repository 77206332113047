import wattAttackLogoWithShadow from '../../../assets/images/logo/WattAttackLogoWithShadow.svg';
import wattAttackLogoLight from '../../../assets/images/logo/WattAttackLogoLight.svg';
import wattAttackLogoDark from '../../../assets/images/logo/WattAttackLogoDark.svg';
import wattAttackLogoShortLight from '../../../assets/images/logo/WattAttackLogoShortLight.svg';
import wattAttackLogoShortDark from '../../../assets/images/logo/WattAttackLogoShortDark.svg';
import wattAttackLogoSquareLight from '../../../assets/images/logo/WattAttackLogoSquareLight.svg';
import wattAttackLogoSquareDark from '../../../assets/images/logo/WattAttackLogoSquareDark.svg';
import { DEFAULT_LOGO_SIZE } from '../../../const';
import classes from './WattAttackLogo.module.css';

type WattAttackLogoProps = {
    width?: string;
    height?: string;
    className?: string;
    variant?:
        | 'full'
        | 'light'
        | 'dark'
        | 'short-dark'
        | 'short-light'
        | 'square-dark'
        | 'square-light';
};

export function WattAttackLogo({
    width = DEFAULT_LOGO_SIZE.width,
    height = DEFAULT_LOGO_SIZE.height,
    className,
    variant = 'light'
}: WattAttackLogoProps): React.JSX.Element {
    let logoSource = wattAttackLogoLight;

    switch (variant) {
        case 'full':
            logoSource = wattAttackLogoWithShadow;
            break;
        case 'dark':
            logoSource = wattAttackLogoDark;
            break;
        case 'short-dark':
            logoSource = wattAttackLogoShortDark;
            break;
        case 'short-light':
            logoSource = wattAttackLogoShortLight;
            break;
        case 'square-dark':
            logoSource = wattAttackLogoSquareDark;
            break;
        case 'square-light':
            logoSource = wattAttackLogoSquareLight;
            break;
        case 'light':
        default:
            logoSource = wattAttackLogoLight;
            break;
    }

    const imgClass = className ? `${classes.logo} ${className}` : classes.logo;

    return (
        <img
            className={imgClass}
            src={logoSource}
            width={width}
            height={height}
            alt="WattAttack Logo."
        />
    );
}
