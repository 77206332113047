import { useStores } from '../../../../root-store-context';
import { observer } from 'mobx-react-lite';
import useLocale from '../../../../CustomHooks/useLocale';
import { NavLink, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { NAVIGATION_ROUTES } from '../../../../const';
import classes from './CabinetMainMenu.module.css';

type CabinetMainMenuProps = {
    className?: string;
};

function CabinetMainMenu({ className }: CabinetMainMenuProps) {
    const { localeUrlPrefix } = useLocale();
    const { user } = useStores();
    const location = useLocation();

    return (
        <ul className={`${classes['cabinet-main-menu']} ${className}`}>
            <li className={classes['cabinet-main-menu__item']}>
                <NavLink
                    className={classes['cabinet-main-menu__link']}
                    to={`${localeUrlPrefix}${NAVIGATION_ROUTES.how_to_start}`}
                >
                    <FormattedMessage
                        id="Header_B1QVBw"
                        defaultMessage="Как начать"
                        description="элемент меню"
                    />
                </NavLink>
            </li>
            <li className={classes['cabinet-main-menu__item']}>
                <NavLink
                    className={classes['cabinet-main-menu__link']}
                    to={`${localeUrlPrefix}${NAVIGATION_ROUTES.about}`}
                >
                    <FormattedMessage
                        id="Header_Rl27q3"
                        defaultMessage="О нас"
                        description="элемент меню"
                    />
                </NavLink>
            </li>
            <li className={classes['cabinet-main-menu__item']}>
                <NavLink
                    className={classes['cabinet-main-menu__link']}
                    to={`${localeUrlPrefix}${NAVIGATION_ROUTES.help}`}
                >
                    <FormattedMessage
                        id="Header_8h6EWK"
                        defaultMessage="Помощь"
                        description="элемент меню"
                    />
                </NavLink>
            </li>
            <li className={classes['cabinet-main-menu__item']}>
                <NavLink
                    className={classes['cabinet-main-menu__link']}
                    to={`${localeUrlPrefix}${NAVIGATION_ROUTES.download}`}
                >
                    <FormattedMessage
                        id="Header_LT99Hk"
                        defaultMessage="Скачать"
                        description="элемент меню"
                    />
                </NavLink>
            </li>
            {user.isLogined &&
                location.pathname !== `${localeUrlPrefix}${NAVIGATION_ROUTES.cabinet}` && (
                    <li className={classes['cabinet-main-menu__item']}>
                        <NavLink
                            className={classes['cabinet-main-menu__link']}
                            to={`${localeUrlPrefix}${NAVIGATION_ROUTES.cabinet}`}
                        >
                            <FormattedMessage
                                id="Header_FgmjAT"
                                defaultMessage="Кабинет"
                                description="элемент меню"
                            />
                        </NavLink>
                    </li>
                )}
            {!user.isLogined && (
                <li className={classes['cabinet-main-menu__item']}>
                    <NavLink
                        className={classes['cabinet-main-menu__link']}
                        to={`${localeUrlPrefix}${NAVIGATION_ROUTES.login}`}
                    >
                        <FormattedMessage
                            id="Header_tNozdx"
                            defaultMessage="Войти"
                            description="элемент меню"
                        />
                    </NavLink>
                </li>
            )}
        </ul>
    );
}

export default observer(CabinetMainMenu);
