import { useIntl } from 'react-intl';
import { useMediaQuery, useTheme } from '@mui/material';
import AppLayout from '../../layouts/AppLayout/AppLayout';
import MainHeader from '../../MainHeader/MainHeader';
import MainHero from '../../MainHero/MainHero';
import HowToStart from '../../HowToStart/HowToStart';
import Tracks from '../../Tracks/Tracks';
import Workouts from '../../Workouts/Workouts';
import Competitions from '../../Competitions/Competitions';
import About from '../../About/About';
import Subscription from '../../Subscription/Subscription';
import DownloadApp from '../../DownloadApp/DownloadApp';
import FAQ from '../../FAQ/FAQ';
import Feedback from '../../Feedback/Feedback';
import MainFooter from '../../MainFooter/MainFooter';
import FloatingTelegramButton from '../../FloatingTelegramButton/FloatingTelegramButton';

function MainPage() {
    const intl = useIntl();
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <AppLayout>
            <MainHeader variant="landing" />
            <main>
                <MainHero />
                <HowToStart />
                <Tracks />
                <Workouts />
                <Competitions />
                <About />
                <Subscription />
                <DownloadApp />
                {/* <FAQ /> */}
                <Feedback />
            </main>
            <MainFooter />
            {intl.locale === 'ru' && !isTablet && <FloatingTelegramButton />}
        </AppLayout>
    );
}

export default MainPage;
