import { buildApiUrl } from './api';

type FeedbackRequest = {
    name: string;
    email: string;
    text: string;
};

type TelegramResponse = {
    success: boolean;
    message?: string;
    error?: string;
};

export default async function FeedbackService(request: FeedbackRequest): Promise<TelegramResponse> {
    try {
        const response = await fetch(buildApiUrl('/send-question'), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name: request.name,
                email: request.email,
                text: request.text
            })
        });

        if (!response.ok) {
            throw new Error('Failed to send message');
        }

        return { success: true };
    } catch (error) {
        console.error('Error while sending message:', error);
        return { success: false, error: 'An error occurred while sending the message' };
    }
}
