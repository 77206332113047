import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../../root-store-context';
import useLocale from '../../../../CustomHooks/useLocale';
import FullPageSpinner from '../../FullPageSpinner/FullPageSpinner';
import { NAVIGATION_ROUTES, OAUTH_PROVIDERS, OAuthProviders } from '../../../../const';

const OAuthCallback = () => {
    const location = useLocation();
    const { localeUrlPrefix } = useLocale();
    const navigate = useNavigate();
    const { user } = useStores();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const payload = params.get('payload');
        const state = params.get('state');
        const code = params.get('code');

        const getProvider = (state: string | null): OAuthProviders | null => {
            if (document.referrer === 'https://oauth.mail.ru/') {
                return OAUTH_PROVIDERS.mailRu;
            }
            if (state) {
                return state as OAuthProviders;
            }
            return null;
        };

        const provider = getProvider(state);

        if (!provider) {
            console.error('Unknown provider');
            navigate(`${localeUrlPrefix}${NAVIGATION_ROUTES.registration}`);
            return;
        }

        const handleAuthResponse = async (
            provider: OAuthProviders,
            code: string,
            state: string
        ) => {
            await user.oAuthLogin(provider, code, state);

            if (user.state === 'ok') {
                navigate(`${localeUrlPrefix}${NAVIGATION_ROUTES.cabinet}`);
            } else {
                if (user.error === 'There is not enough information in profile') {
                    navigate(`${localeUrlPrefix}${NAVIGATION_ROUTES.registration}`);
                } else {
                    console.error('Auth failed:', user.error);
                    navigate(`${localeUrlPrefix}${NAVIGATION_ROUTES.login}`);
                }
            }
        };

        if (payload) {
            const { token, uuid } = JSON.parse(decodeURIComponent(payload));
            handleAuthResponse(provider, token, uuid);
        } else if (code && state) {
            handleAuthResponse(provider, code, state);
        } else {
            console.error('False OAuth response');
            navigate(`${localeUrlPrefix}${NAVIGATION_ROUTES.login}`);
        }
    }, []);

    return <FullPageSpinner />;
};

export default observer(OAuthCallback);
