import { buildApiUrl } from './api';
type PromoCode = {
    id: string;
    code: string | null;
    expiresAt: string | null;
    discountType: string | null;
    discountValue: number | null;
    usageLimit: number | null;
    applicablePlans?: string[];
    description: string | null;
};
type PromoCodeValidationRequest = {
    code: string;
};

type PromoCodeValidationResponse = {
    valid: boolean;
    promoCode?: PromoCode;
    error?: string;
};

export default async function validatePromoCode(request: PromoCodeValidationRequest): Promise<PromoCodeValidationResponse> {
    try {
        const response = await fetch(buildApiUrl('/promocode/validate'), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                code: request.code
            })
        });

        if (!response.ok) {
            throw new Error('Failed to validate promo code');
        }

        const data = await response.json();

        return {
            valid: data.valid,
            promoCode: data.promoCode
        };
    } catch (error) {
        console.error('Error while validating promo code:', error);
        return { valid: false, error: 'An error occurred while validating the promo code' };
    }
}
