import AthleteStore from './AthleteStore';
import UserStore from './UserStore';
import SubscriptionStore from './SubscriptionStore';

class RootStore {
    user: UserStore;
    athlete: AthleteStore;
    subscription: SubscriptionStore;

    constructor() {
        this.user = new UserStore();
        this.athlete = new AthleteStore();
        this.subscription = new SubscriptionStore();
    }

    async init() {
        await this.user.init();
    }
}

export default RootStore;
