import { createContext, useContext } from 'react';

import RootStore from './stores/RootStore';

export const RootStoreContext = createContext<RootStore | null>(null);
export const RootStoreProvider = RootStoreContext.Provider;

export const useStores = () => {
    const context = useContext(RootStoreContext);

    if (context === null) {
        throw new Error('Do not forget to wrap component in `Context.provider`');
    }

    return context;
};
