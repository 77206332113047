import Typography from '@mui/material/Typography';
import Fade from '@mui/material/Fade';
import { MESSAGE_TRANSITION_TIMEOUT } from '../../../const';
import classes from './TextFieldWrapper.module.css';

type TextFieldWrapperProps = {
    children?: React.ReactNode;
    helper?: string;
    errorText?: string;
    errorId?: string;
    showErrors?: boolean;
};

function TextFieldWrapper({
    children,
    helper,
    errorText,
    errorId,
    showErrors
}: TextFieldWrapperProps) {
    return (
        <div className={classes['text-field-wrapper']}>
            {helper && (
                <Typography variant="body2" className={classes['text-field-wrapper__helper']}>
                    {helper}
                </Typography>
            )}

            {children}
            <div className={classes['text-field-wrapper__error-slot']}>
                {errorText && showErrors && (
                    <Fade in={showErrors} timeout={MESSAGE_TRANSITION_TIMEOUT}>
                        <p
                            className={`${classes['text-field-wrapper__helper']} ${classes['text-field-wrapper__helper--error']}`}
                            role="alert"
                            id={errorId}
                        >
                            {errorText}
                        </p>
                    </Fade>
                )}
            </div>
        </div>
    );
}

export default TextFieldWrapper;
