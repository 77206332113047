import AppLayout from '../../layouts/AppLayout/AppLayout';
import MainHeader from '../../MainHeader/MainHeader';
import MainFooter from '../../MainFooter/MainFooter';
import ActivateUser from '../../Auth/ActivateUser/ActivateUser';

function ActivateUserPage() {
    return (
        <AppLayout>
            <MainHeader variant="cabinet" />
            <main>
                <ActivateUser />
            </main>
            <MainFooter />
        </AppLayout>
    );
}

export default ActivateUserPage;
